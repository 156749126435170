import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { InfluencerFilterProps } from './filterTypes';
import './Filter.css';

interface SelectedItems {
    [key: string]: string[];
}

const InfluencerFilter: React.ForwardRefRenderFunction<{ resetFilter: () => void }, InfluencerFilterProps> = ({ influencers, onSelectedItemsChange }, ref) => {
    const [selectedItems, setSelectedItems] = useState<SelectedItems>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [selectedCategories, setSelectedCategories] = useState<Record<string, boolean>>({});
    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>({});

    useEffect(() => {
        const initialCollapsedState = influencers.reduce((acc, category) => {
            acc[category.id] = true;
            return acc;
        }, {} as Record<string, boolean>);
        setCollapsedCategories(initialCollapsedState);
    }, [influencers]);

    useEffect(() => {
        const updatedCollapsedCategories = influencers.reduce((acc, category) => {
            const isCategoryMatch = category.name.toLowerCase().includes(searchTerm.toLowerCase());
            const filteredSubItems = isCategoryMatch
                ? category.subItems
                : category.subItems.filter(subItem => subItem.user_username.toLowerCase().includes(searchTerm.toLowerCase()));

            acc[category.id] = searchTerm ? false : true; // Expand matching categories
            return acc;
        }, {} as Record<string, boolean>);

        setCollapsedCategories(updatedCollapsedCategories);
    }, [searchTerm, influencers]);

    useImperativeHandle(ref, () => ({
        resetFilter
    }));

    const resetFilter = () => {
        setSelectedCategories({});
        setSelectedItems({});
        setSearchTerm('');
        onSelectedItemsChange([]);
    };

    const filteredCategories = influencers.map(category => {
        const isCategoryMatch = category.name.toLowerCase().includes(searchTerm.toLowerCase());
        const filteredSubItems = isCategoryMatch
            ? category.subItems
            : category.subItems.filter(subItem => subItem.user_username.toLowerCase().includes(searchTerm.toLowerCase()));

        return {
            ...category,
            subItems: filteredSubItems
        };
    }).filter(category =>
        category.subItems.length > 0 || category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCategorySelection = (categoryId: string, isSelected: boolean) => {
        if (isSelected) {
            const selectedSubItems = influencers.find(category => category.id === categoryId)?.subItems.map(subItem => subItem.user_username) || [];
            setSelectedItems(prevItems => ({
                ...prevItems,
                [categoryId]: selectedSubItems
            }));
            setSelectedCategories(prev => ({
                ...prev,
                [categoryId]: true
            }));
        } else {
            setSelectedItems(prevItems => {
                const newItems = { ...prevItems };
                delete newItems[categoryId];
                return newItems;
            });
            setSelectedCategories(prev => {
                const newCategories = { ...prev };
                delete newCategories[categoryId];
                return newCategories;
            });
        }
        setSelectedItems(prevSelectedItems => {
            const allSubItemUsernames = Object.values(prevSelectedItems).flat();
            onSelectedItemsChange(allSubItemUsernames);
            return prevSelectedItems;
        });
    };

    const handleSubItemSelection = (categoryId: string, subItemUsername: string, isSelected: boolean) => {
        setSelectedItems(prevSelectedItems => {
            const updatedSelectedItems = { ...prevSelectedItems };
            if (!updatedSelectedItems[categoryId]) {
                updatedSelectedItems[categoryId] = [];
            }
            if (isSelected) {
                if (!updatedSelectedItems[categoryId].includes(subItemUsername)) {
                    updatedSelectedItems[categoryId].push(subItemUsername);
                }
            } else {
                updatedSelectedItems[categoryId] = updatedSelectedItems[categoryId].filter(username => username !== subItemUsername);
                if (updatedSelectedItems[categoryId].length === 0) {
                    delete updatedSelectedItems[categoryId];
                }
            }
            return updatedSelectedItems;
        });
        setSelectedItems(prevSelectedItems => {
            const allSelectedSubItems = Object.values(prevSelectedItems).flat();
            onSelectedItemsChange(allSelectedSubItems);
            return prevSelectedItems;
        });
    };

    const selectAllCategories = () => {
        const newSelectedCategories = influencers.reduce((acc, category) => {
            acc[category.id] = true;
            return acc;
        }, {} as Record<string, boolean>);
        setSelectedCategories(newSelectedCategories);
        const newSelectedItems = influencers.reduce((acc, category) => {
            acc[category.id] = category.subItems.map(subItem => subItem.user_username);
            return acc;
        }, {} as Record<string, string[]>);
        setSelectedItems(newSelectedItems);
        const allSubItemNames = Object.values(newSelectedItems).flat();
        onSelectedItemsChange(allSubItemNames);
    };

    const deselectAllCategories = () => {
        setSelectedCategories({});
        setSelectedItems({});
        onSelectedItemsChange([]);
        setSearchTerm('');
    };

    const toggleCategoryCollapse = (categoryId: string) => {
        setCollapsedCategories(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const renderSelectedItems = () => {
        if (Object.values(selectedItems).flat().length > 0) {
            return Object.entries(selectedItems).map(([categoryId, subItemUsernames]) => {
                const category = influencers.find(category => category.id === categoryId);
                return (
                    <div key={categoryId}>
                        <div className="selected-category">
                            Category - {category?.name}
                            <span className="deselect-icon" onClick={() => handleCategorySelection(categoryId, false)}>x</span>
                        </div>
                        {subItemUsernames.map(username => (
                            <div key={username} className="selected-sub-item">
                                {username}
                                <span className="deselect-icon" onClick={() => handleSubItemSelection(categoryId, username, false)}>x</span>
                            </div>
                        ))}
                    </div>
                );
            });
        } else {
            return "No influencer selected";
        }
    };

    return (
        <div ref={dropdownRef} className='filter-dropdown-container'>
            <div className="search-input-container">
                <input
                    type="text"
                    placeholder="Search influencers..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setIsDropdownVisible(true);
                    }}
                    onFocus={() => setIsDropdownVisible(true)}
                    className="filter-search-input"
                    style={{ paddingRight: '30px' }}
                />
                {searchTerm && (
                    <div
                        className="clear-search-button"
                        onClick={() => setSearchTerm('')}
                    >
                        &#x2715;
                    </div>
                )}
            </div>
            {isDropdownVisible && (
                <div className="category-dropdown">
                    <div className="select-deselect-buttons">
                        <button className='select-all-btn' onClick={selectAllCategories}>Select All</button>
                        <button className='de-select-all-btn' onClick={deselectAllCategories}>Deselect All</button>
                    </div>
                    <div className="categories-and-subcategories-section">
                        {filteredCategories.length > 0 ? (
                            filteredCategories.map(category => {
                                const isCategoryCompletelySelected = selectedItems[category.id]?.length === category.subItems.length;

                                return (
                                    <div key={category.id} className="category-item">
                                        <div className='category-item-group'>
                                            <div className='ml-3' onClick={() => toggleCategoryCollapse(category.id)}>
                                                {collapsedCategories[category.id] ? (<Icon.CaretDownFill className='filter-category-toggle-icon'></Icon.CaretDownFill>) :
                                                    (<Icon.CaretUpFill className='filter-category-toggle-icon'></Icon.CaretUpFill>)}
                                            </div>
                                            <label className="category-label">
                                                <input
                                                    type="checkbox"
                                                    checked={isCategoryCompletelySelected}
                                                    onChange={(e) => handleCategorySelection(category.id, e.target.checked)}
                                                    className="category-checkbox"
                                                />
                                                {category.name}
                                            </label>
                                        </div>
                                        {!collapsedCategories[category.id] && (
                                            <div className="sub-item-container">
                                                {category.subItems.map(subItem => (
                                                    <label key={subItem.user_username} className="sub-item-label">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems[category.id]?.includes(subItem.user_username) || false}
                                                            onChange={(e) => handleSubItemSelection(category.id, subItem.user_username, e.target.checked)}
                                                            className="sub-item-checkbox"
                                                        />
                                                        {subItem.user_username}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-results">No matching influencers found.</div>
                        )}
                    </div>
                </div>
            )}
            <div className="selected-items-display text-center">
                {renderSelectedItems()}
            </div>
            <div className='mx-auto text-center'>
                {Object.values(selectedItems).flat().length > 0 &&
                    <button onClick={resetFilter} className="mt-2 reset-filter-button">Reset Filters</button>
                }
            </div>
        </div>
    );
};

export default forwardRef(InfluencerFilter);
