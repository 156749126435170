import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Table, Spinner, Button, Tooltip, Modal, Overlay, Popover } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useAccount } from 'wagmi'
import toast, { Toaster } from 'react-hot-toast';
import { useUser } from '../components/UserContext';
// import TokenFilter from '../components/TokenFilter';
import TokenFilter from '../components/TokenFilterNew';
import TokenFilterForHome from '../components/TokenFilterForHome';
import InfluencerFilter from '../components/InfluencerFilter';
import KeywordFilter from '../components/KeywordFilter';
import EcoSystemFilter from '../components/EcosystemFilter';
import CategoryFilter from '../components/CategoryFilter';
import { transformInfluencersToCategories, transformTokensToCategories } from '../components/utils';
import { Influencer, InfluencerCategory, TokenCategory, Token } from '../components/filterTypes';
import { Tweet } from 'react-tweet';
import { parseISO, format } from 'date-fns';
import bit_coin_symbol_img from "../assets/images/bit-coin-symbol.png"
import star_table_img from "../assets/icons/Star 3.png"
import star_fill_img from "../assets/images/Star.png"
import default_crypto_img from "../assets/images/default-crypto-img.png"
import no_img from "../assets/images/no-image-icon.png"

import './all-influencers.css'

interface Tweet {
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    image_url: string;
    user_username: string;
    dexscreen_url: string;
    token_sniffer_url: string;
}


type TweetsByDate = {
    [key: string]: Tweet[];
};

interface CryptoTableRowProps {
    tweets: Tweet[];
}


// const tweetsByDate = {
//     '2023-12-20': [
//         '1603688071106424833',
//         '1603688071106424833',
//         '1603688071106424833',
//         '1603688071106424833',
//         '1603688071106424833',
//         '1603688071106424833',
//         '1603688071106424833'
//     ],
//     '2023-12-21': [
//         '1603688071106424833',
//         '1603688071106424833'
//     ],
// };

interface InfluencerFilterData {
    user_name: string;
    user_username: string;
    user_verified: boolean;
    user_profile_image_url: string;
    // ... other properties
}

interface TokenOption {
    value: string;
    // ... other properties, if they exist
}


interface ViewTweet {
    complete_text: string;
    tweet_id: string;
    publishable: boolean;
    user_profile_image_url: string;
    user_name: string;
    username: string;
    created_at: string;
}

const Avatars: React.FC<CryptoTableRowProps> = ({ tweets }) => {
    const { address, isConnected } = useAccount()
    const maxAvatars = 5;
    const remainingCount = tweets.length - maxAvatars;
    const [searchTerm, setSearchTerm] = useState("");
    const [isSortedByDate, setIsSortedByDate] = useState(true);
    const [showTweetModal, setShowTweetModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState<string | null>(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTweet, setSelectedTweet] = useState<ViewTweet | null>(null);
    const [userSelectedTweet, setUserSelectedTweet] = useState<ViewTweet | null>(null);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredTweets = tweets
        .filter(tweet => tweet.cryptocurrency?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            if (isSortedByDate) {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const handleSortByDate = () => {
        setIsSortedByDate(!isSortedByDate);
    };


    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };


    const handleViewTweet = async (id: string) => {


        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(id);
                // setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }

    };

    const handleBack = () => {
        setViewTweetId(null);
    };


    const handleAvatarClick = async (tweet: Tweet) => {
        console.log(tweet)
        setViewTweetId(null);
        setUserSelectedTweet(null);
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet.tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet.tweet_id];
                console.log(tweetData)
                if (tweetData.publishable) {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    };

    return (
        <div>
            <div className="stacked-avatars">
                {tweets.slice(0, maxAvatars).map((tweet, index) => (
                    <div key={index} className="avatars__item" style={{ zIndex: tweets.length - index }} onClick={() => handleAvatarClick(tweet)}>
                        <img className="avatar-img" src={tweet.image_url || no_img} alt="user-avatar" />
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div className="avatars__item avatars__more" style={{ zIndex: 0 }} onClick={() => setShowTweetModal(true)}>
                        +{remainingCount}
                    </div>
                )}
            </div>

            <Modal className='tweet-modal modal-lg' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered style={{ zIndex: 1050 }}>
                <Modal.Header className="justify-content-between">
                    <div className='h-tweet-modal-h'>Tweets</div>
                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-container">
                        {viewTweetId && userSelectedTweet ? (
                            <div>
                                <button className="back-btn" onClick={handleBack}>Back To Tweets</button>
                                <div data-theme="dark" className='modal-tweet-container'>

                                    {userSelectedTweet && userSelectedTweet.publishable ? (
                                        <Tweet id={userSelectedTweet.tweet_id} />
                                    ) : (
                                        <div className="not-found-tweet-container">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={userSelectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                                </div>
                                                <div>
                                                    <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                    <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>
                                                </div>
                                            </div>
                                            <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                            <div className='token-detail-timestamp'>
                                                {
                                                    convertToLocalTime(userSelectedTweet.created_at)
                                                }
                                            </div>
                                            <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(userSelectedTweet.tweet_id)}>
                                                Go To Tweet
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="search-sort-container">
                                    <input
                                        type="text"
                                        placeholder="Search by Token..."
                                        className="search-bar"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="sort-btn" onClick={handleSortByDate}>
                                        Sort by Date {isSortedByDate ? '(Descending)' : '(Ascending)'}
                                    </button>
                                </div>
                                <div className="investor-list">
                                    {filteredTweets.map((tweet, index) => (
                                        <div key={index} className="investor-item">
                                            <img className="avatar-img-small" src={tweet.image_url || no_img} alt="user-avatar" />

                                            <div className="investor-info">
                                                <div className="investor-name">{tweet.cryptocurrency}</div>
                                                <div className="investor-type">{tweet.crypto_symbol}</div>

                                                <div className="tweet-date">
                                                    {new Date(tweet.created_at).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}{' '}
                                                    {new Date(tweet.created_at).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })}
                                                </div>
                                            </div>
                                            <button className="view-tweet-btn-modal" onClick={() => handleViewTweet(tweet.tweet_id)} >View Tweet</button>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            {selectedTweet && (
                <Modal className='tweet-modal' show={showDetailModal} onHide={() => setShowDetailModal(false)} centered style={{ zIndex: 1050 }}>
                    <Modal.Header className="justify-content-between">
                        <div className='h-tweet-modal-h'>Tweet Details</div>
                        <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowDetailModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <div data-theme="dark" className='modal-tweet-container'>
                            {selectedTweet.publishable ? (
                                <Tweet id={selectedTweet.tweet_id} />
                            ) : (
                                <div className="not-found-tweet-container">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={selectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                        </div>
                                        <div>
                                            <div className="ms-1 ticker-name">{selectedTweet.user_name}</div>
                                            <div className="ms-1 ticker-symbol">@{selectedTweet.username}</div>
                                        </div>
                                    </div>
                                    <div className="tweet-complete-text">{selectedTweet.complete_text}</div>
                                    <div className='token-detail-timestamp'>
                                        {
                                            convertToLocalTime(selectedTweet.created_at)
                                        }
                                    </div>
                                    <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(selectedTweet.tweet_id)}>
                                        Go To Tweet
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}

        </div>
    );
};

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

// interface Token {
//     token: string;
//     symbol: string;
// }

interface Filters {
    tokens: Token[];
    keywords: string[];
    influencer: Influencer[];
}

interface InfluencerProfile {
    description: string;
    username: string;
    name: string;
    public_metrics: {
        followers_count: number;
        following_count: number;
        tweet_count: number;
        listed_count: number;
        like_count: number;
    },
    entities: {
        url: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>
        },
        description: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>,
            mentions: Array<{
                start: number;
                end: number;
                username: string;
            }>
        }
    },
    url: string;
    location: string;
    created_at: string;
    verified: boolean;
    id: string;
    profile_image_url: string;
    pinned_tweet_id: string;
    protected: boolean;
    category: string;
}

export const AllInfluencers = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { user, addToWatchlist, removeFromWatchlist, watchlistInfluencer } = useUser();
    const [isInfluencerWatchlistChecked, setIsInfluencerWatchlistChecked] = useState(false);
    const [isTokenWatchlistChecked, setIsTokenWatchlistChecked] = useState(false);
    const { address, isConnected } = useAccount()
    const [allInfluencersTimeWindow, setAllInfluencersTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [loader, setLoader] = useState(false);
    const [allInfluencerTweet, setAllInfluencerTweet] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerCategory[]>([]);
    // const [tokenFilter, setTokenFilter] = useState<TokenCategory[]>([]);
    const [tokenFilter, setTokenFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [tokenFilterNew, setTokenFilterNew] = useState<Token[]>([]);

    //Filter
    const [filters, setFilters] = useState<Filters>({ tokens: [], keywords: [], influencer: [] });
    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        setFilteredTokens(selectedValues)
    };

    const handleSelectedItemsChange = (selectedItems: string[]) => {
        setSelectedInfluencers(selectedItems);
    };

    const handleKeywordSelectionChange = (selectedValues: string[]) => {
        setFilteredKeywords(selectedValues)
    };


    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };


    const get_all_token_tweet = async (timeWindow: any, page: any, token: any, keyword: any, influencer: any) => {
        setLoader(true)

        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const watchlistParam = isInfluencerWatchlistChecked ? '&influencer_watchlist=True' : '&influencer_watchlist=False';
        const tokenWatchlistParam = isTokenWatchlistChecked ? '&token_watchlist=True' : '&token_watchlist=False';

        const url = `${process.env.REACT_APP_API_URL}influencer/pov?time_window=${timeWindow}&page=${page}${walletIdParam}${watchlistParam}${tokenWatchlistParam}`;

        const requestBody = {
            influencer: influencer ? influencer.join(',') : null,
            token: token ? token.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
            mmt_category: (!token || token.length === 0) && filteredCategories ? filteredCategories.join(',') : null,
            ecosystem: (!token || token.length === 0) && filteredEcosystems ? filteredEcosystems.join(',') : null
        };


        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });
            if (response.status === 200) {
                const data = await response.json();
                setAllInfluencerTweet(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
                setLoader(false)
            } else {
                console.log(response.status);
                setLoader(false)
            }
        } catch (e) {
            console.log(e);
            setLoader(false)
        }
    };


    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setFilters(data.filters);
                const categoriesWithInfluencers = transformInfluencersToCategories(data['filters']['influencer']);
                // const categoriesWithTokens = transformTokensToCategories(data['filters']['tokens']);

                // Define the desired order
                const desiredOrder = ["Ethereum", "Solana", "Base", "Arbitrum One", "Optimism", "Polygon POS", "Polygon zkEVM", "TON", "Avalanche", "BNB Smart Chain", "zkSync", "Aptos", "Sui", "Fantom", "Cosmos", "Hyperliquid", "Linea", "Arbitrum Nova",  "BNB Beacon Chain"];

                // Sort the ecosystem array
                const sortedEcosystem = data['filters']['ecosystem'].sort((a: string, b: string) => {
                    const indexA = desiredOrder.indexOf(a);
                    const indexB = desiredOrder.indexOf(b);

                    // If either index is -1, move it to the end of the sorted array
                    if (indexA === -1) return 1;
                    if (indexB === -1) return -1;

                    return indexA - indexB;
                });

                // Set the state with the formatted data
                setTokenFilter(sortedEcosystem);

                setCategoryFilter(data['filters']['mmt_categories'])
                // setTokenFilterNew(data['filters']['tokens'])
                // Set the state with the formatted data
                setInfluencerFilter(categoriesWithInfluencers);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    useEffect(() => {
        get_all_token_tweet(allInfluencersTimeWindow, currentPage, filteredTokens, filteredKeywords, selectedInfluencers);
    }, [allInfluencersTimeWindow, currentPage, filteredTokens, filteredKeywords, selectedInfluencers, isInfluencerWatchlistChecked, isTokenWatchlistChecked]);


    useEffect(() => {
        get_all_token_filter(allInfluencersTimeWindow);
    }, [allInfluencersTimeWindow]);

    const open_influencer_detail = (id: any) => {
        window.open("/influencer-detail/" + id, "_blank")
    }

    const checkIfExistsOnWatchList = (id: string): string | null => {
        if (watchlistInfluencer?.length > 0) {
            const item = watchlistInfluencer.find(item => item.data === id);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleWatchlistToggle = async (id: any) => {
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfExistsOnWatchList(id);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Influencer', isInWatchlist, user?.wallet_id)
            } else {
                console.log(user)
                addToWatchlist('Influencer', id, user?.wallet_id)
            }
        }
        // Optionally, refresh the watchlist data to reflect the update
    };


    // Handle checkbox change
    const handleInfluencerCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsInfluencerWatchlistChecked(e.target.checked);
    };

    const handleTokenCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsTokenWatchlistChecked(e.target.checked);
    };


    const [profileDetails, setProfileDetails] = useState<InfluencerProfile | null>(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [isPopoverHovered, setIsPopoverHovered] = useState(false);
    const ref = useRef(null);

    const fetchProfileDetails = async (username: any) => {
        setLoadingProfile(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}influencer/info?influencer=${username}`, {
                method: 'GET',
            });
            const data = await response.json();
            setProfileDetails(data['data']);
        } catch (error) {
            console.error("Error fetching profile details:", error);
        } finally {
            setLoadingProfile(false);
        }
    };

    const handleMouseEnter = (event: any, username: any) => {
        setTarget(event.target);
        setShow(true);
        fetchProfileDetails(username);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!isPopoverHovered) {
                setShow(false);
                setProfileDetails(null);
            }
        }, 100);
    };

    const handlePopoverMouseEnter = () => {
        setIsPopoverHovered(true);
    };

    const handlePopoverMouseLeave = () => {
        setIsPopoverHovered(false);
        setShow(false);
    };

    const handleLinkClick = (event: any) => {
        event.stopPropagation();
    };

    //Ecosystem and Category Filter Group
    const ecosystemFilterRef = useRef<{ resetFilter: () => void }>(null);
    const categoryFilterRef = useRef<{ resetFilter: () => void }>(null);
    const tokenFilterRef = useRef<{ resetFilter: () => void }>(null);
    const influencerFilterRef = useRef<{ resetFilter: () => void }>(null);
    const keywordFilterRef = useRef<{ resetFilters: () => void }>(null);
    const [selectedFilterType, setSelectedFilterType] = useState('all');
    const [isEcosystemFilterApplied, setIsEcosystemFilterApplied] = useState(false);
    const [isCategoryFilterApplied, setIsCategoryFilterApplied] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [filteredEcosystems, setFilteredEcosystems] = useState<string[]>([]);


    const handleResetClick = () => {
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
    };

    const handleResetEcosystemFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
    };

    const handleResetCategoryFilters = () => {
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
    };

    const handleResetAllFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
        if (keywordFilterRef.current) {
            keywordFilterRef.current.resetFilters();
        }
        if (influencerFilterRef.current) {
            influencerFilterRef.current.resetFilter();
        }
        // Additional reset logic if required
    };

    const handleFilterTypeRadioChange = (event: any) => {
        setSelectedFilterType(event.target.value);
    };

    const handleEcosystemSelectionChange = (selectedValues: string[]) => {
        setFilteredEcosystems(selectedValues)
        setIsEcosystemFilterApplied(selectedValues.length > 0);
        handleResetClick();
    };

    const handleCategorySelectionChange = (selectedValues: string[]) => {
        setIsCategoryFilterApplied(selectedValues.length > 0);
        setFilteredCategories(selectedValues)
        handleResetClick();
    };

    const fetch_token_based_on_ecosystem_and_mmtcategory = async (category: any, ecosystem: any) => {
        const url = `${process.env.REACT_APP_API_URL}home/filters-pinpoint`;

        const payload = JSON.stringify({
            mmt_categories: category ? category.join(',') : null,
            ecosystem: ecosystem ? ecosystem.join(',') : null
        });

        const headers = {
            'Content-Type': 'application/json'
        };

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: payload
            });

            const data = await res.json();
            setTokenFilterNew(data['data']);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        if (selectedFilterType == 'all') {
            get_all_token_tweet(allInfluencersTimeWindow, currentPage, filteredTokens, filteredKeywords, selectedInfluencers);
        }
        if (selectedFilterType == 'token') {
            fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
        }
    }, [filteredCategories, filteredEcosystems]);


    useEffect(() => {
        fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
    }, []);

    /**  */



    // Effect to reset checkbox if the user disconnects
    useEffect(() => {
        if (!isConnected) {
            setIsInfluencerWatchlistChecked(false)
            setIsTokenWatchlistChecked(false)
        }
    }, [isConnected]);

    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                {loader && (
                    <div className="disabled-overlay">
                        Fetching Data...
                    </div>
                )}
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className={isMobile ? '' : 'd-flex'}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        All Influencers
                                    </div>

                                </div>
                                <div className='time-window-container d-flex'>
                                    <div onClick={() => setAllInfluencersTimeWindow('15min')} className={`time-window ${allInfluencersTimeWindow == '15min' ? 'active' : ''}`}>
                                        15 min
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('1hr')} className={`time-window ${allInfluencersTimeWindow == '1hr' ? 'active' : ''}`}>
                                        1h
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('4hr')} className={`time-window ${allInfluencersTimeWindow == '4hr' ? 'active' : ''}`}>
                                        4h
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('12hr')} className={`time-window ${allInfluencersTimeWindow == '12hr' ? 'active' : ''}`}>
                                        12h
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('1day')} className={`time-window ${allInfluencersTimeWindow == '1day' ? 'active' : ''}`}>
                                        1d
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('1week')} className={`time-window ${allInfluencersTimeWindow == '1week' ? 'active' : ''}`}>
                                        7d
                                    </div>
                                    <div onClick={() => setAllInfluencersTimeWindow('1month')} className={`time-window ${allInfluencersTimeWindow == '1month' ? 'active' : ''}`}>
                                        30d
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>

                                        <Col sm={12} md={12} lg={12}>
                                            <Card className="mb-3 filter-group-home">
                                                {/* <Card.Header>Filter Group</Card.Header> */}
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <Form>
                                                                <div className="d-inline-block me-5">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search by Ecosystem/Category"
                                                                        name="filterGroup"
                                                                        value="all"
                                                                        checked={selectedFilterType === 'all'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>
                                                                <div className="d-inline-block">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search Token"
                                                                        name="filterGroup"
                                                                        value="token"
                                                                        checked={selectedFilterType === 'token'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <div className="reset-all-filter-btn" onClick={handleResetAllFilters}>
                                                                Reset All Filters
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Ecosystem</div>
                                                            <EcoSystemFilter ref={ecosystemFilterRef} ecosystems={tokenFilter} onSelectionChange={handleEcosystemSelectionChange} />
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Category</div>
                                                            <CategoryFilter ref={categoryFilterRef} categories={categoryFilter} onSelectionChange={handleCategorySelectionChange} />
                                                        </Col>
                                                        {selectedFilterType === 'token' && (
                                                            <Col sm={12} md={4}>
                                                                <div className="filter-label">Filter by Token</div>

                                                                {(isEcosystemFilterApplied || isCategoryFilterApplied) && (
                                                                    <div className="filter-notes">
                                                                        {isEcosystemFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Ecosystem filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetEcosystemFilters}></Icon.X></div>}
                                                                        {isCategoryFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Category filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetCategoryFilters}></Icon.X></div>}
                                                                    </div>
                                                                )}
                                                                <TokenFilterForHome
                                                                    tokens={tokenFilterNew}
                                                                    onSelectedItemsChange={handleTokenSelectionChange}
                                                                    ref={tokenFilterRef}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        {/* <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Token
                                            </div>                                           
                                            <TokenFilter tokens={tokenFilterNew} onSelectedItemsChange={handleTokenSelectionChange} />
                                        </Col> */}
                                        <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Influencer
                                            </div>
                                            <InfluencerFilter ref={influencerFilterRef} influencers={influencerFilter} onSelectedItemsChange={handleSelectedItemsChange} />
                                        </Col>
                                        <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Keywords
                                            </div>
                                            <KeywordFilter ref={keywordFilterRef} keywords={filters.keywords} onSelectionChange={handleKeywordSelectionChange} />
                                        </Col>
                                        <Col sm={3}>
                                            <div className="align-items-center h-100">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Influencer Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isInfluencerWatchlistChecked}
                                                    onChange={handleInfluencerCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Token Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isTokenWatchlistChecked}
                                                    onChange={handleTokenCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section >

            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12} className='mt-3'>

                            {loader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!loader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Influencers Account</th>
                                                    <th>Tweet Link</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allInfluencerTweet.length > 0 ? (
                                                    allInfluencerTweet.map((tokenTweets, index) => (
                                                        <tr key={index} className={allInfluencerTweet.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'3%'}>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(tokenTweets['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(tokenTweets['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td width={'40%'} className='cursor-pointer'
                                                                onClick={() => open_influencer_detail(tokenTweets['id'])}
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <div onMouseEnter={(e) => handleMouseEnter(e, tokenTweets['user_username'])}
                                                                        onMouseLeave={handleMouseLeave}>
                                                                        <img src={tokenTweets['user_profile_image_url']} className="t-d-user-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{tokenTweets['user_name']}</div>
                                                                        <div className="ms-1 ticker-symbol">@{tokenTweets['user_username']}</div>
                                                                        {tokenTweets['user_tag'] &&
                                                                            <div className='influencer-tag'>{tokenTweets['user_tag']}</div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <Overlay target={target} show={show} placement="top" container={ref.current}>
                                                                    <Popover id="popover-basic" className="popover-custom" onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave}>
                                                                        <Popover.Header as="h3">Profile Details</Popover.Header>
                                                                        <Popover.Body>
                                                                            {loadingProfile ? (
                                                                                <div>Loading...</div>
                                                                            ) : (
                                                                                <div>
                                                                                    <p><strong>Bio:</strong> {profileDetails?.description}</p>
                                                                                    {profileDetails?.url && (
                                                                                        <p>
                                                                                            <strong>URL:</strong> <a href={profileDetails.url} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">{profileDetails.url}</a>
                                                                                        </p>
                                                                                    )}
                                                                                    {profileDetails?.entities?.description?.mentions && profileDetails.entities.description.mentions.length > 0 && (
                                                                                        <p>
                                                                                            <strong>Mentions:</strong>{' '}
                                                                                            {profileDetails.entities.description.mentions.map((mention, index) => (
                                                                                                <span key={index}>
                                                                                                    <a href={`https://twitter.com/${mention.username}`} target="_blank" rel="noopener noreferrer">
                                                                                                        @{mention.username}
                                                                                                    </a>
                                                                                                    {index < profileDetails.entities.description.mentions.length - 1 && ', '}
                                                                                                </span>
                                                                                            ))}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                </Overlay>
                                                            </td>

                                                            <td width={'55%'}>
                                                                <Avatars tweets={tokenTweets['tweets']} />
                                                                {/* <CryptoTableRow tweetsByDate={tokenTweets['tweets']} /> */}
                                                            </td>


                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={4} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div>
                                        {/* <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true"><Icon.ArrowLeft></Icon.ArrowLeft> Previous</span>
                                            </a>
                                        </li>
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item dots">...</li>
                                        <li className="page-item"><a className="page-link" href="#">6</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">Next <Icon.ArrowRight></Icon.ArrowRight> </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </section>

        </div >
    )
}

export default AllInfluencers;