import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Table, Spinner, Modal, Button, Overlay, Popover } from "react-bootstrap";
import { useAccount } from 'wagmi'
import * as Icon from 'react-bootstrap-icons';
import { useUser } from '../components/UserContext';
import { Tweet } from 'react-tweet';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import DatePicker from "react-datepicker";
import toast, { Toaster } from 'react-hot-toast';
import { format, parseISO } from 'date-fns';
import twitter_icon from "../assets/icons/twiitter-icon.png"
import star_table_img from "../assets/icons/Star 3.png"
import star_fill_img from "../assets/images/Star.png"
import fav_img from "../assets/icons/Star 1.png"
import dexscreen_img from "../assets/icons/icon-1.png"
import coingecko_img from "../assets/icons/CoinGecko Logo 1.png"
import telegram_img from "../assets/icons/influ-telegram.png"
import no_img from "../assets/images/no-image-icon.png"
import './token-detail.css'
import "react-datepicker/dist/react-datepicker.css";

interface TweetMentionData {
    time_window: string;
    tweets: number;
    influencers: number;
}



interface Tweet {
    id: string;
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    user_profile_picture_url: string;
    user_username: string;
    dexscreen_url: string;
    token_sniffer_url: string;
    complete_text: string;
    publishable: boolean;
    username: string;
    user_name: string;
    tweet: {
        complete_text: string;
        publishable: boolean;
        username: string;
        user_name: string;
        tweet_id: string;
        user_profile_picture_url: string;
        created_at: string;
    }
}


interface Tweet1 {
    id: string;
    complete_text?: string;
    user_profile_image_url?: string;
    user_username?: string;
    publishable?: boolean;
}


interface TweetSummary {
    category: string;
    count: number;
    tweets: Tweet[]
}

type WeeklySummaryItem = {
    category: string;
    count: number;
    tweets: Tweet[];
};


type SentimentCounts = {
    count: number;
    tweets: Tweet[];
};

interface TweetSummaryData {
    summary: TweetSummary[];
    tweets: Tweet[];
    tldr: Array<{
        tldr: string;
        user_name: string;
        user_username: string;
        user_verified: boolean;
        tweet_id: string;
        // ... other properties
    }>;
}



interface CoingeckoStatisticsData {
    market_cap: string;
    high_24h: string;
    fdv: string;
    total_supply: string;
    max_supply: string;
    circulating_supply: string;
    price_usd: string;
    coingecko_url: string;
    token_description: string;
    price_change_percentage_24h_in_currency: string;
    price_change_percentage_7d_in_currency: string;
    price_change_percentage_30d_in_currency: string;
    links: {
        twitter_screen_name: string,
        telegram_channel_identifier: string
    }
}

interface DexscreenerStatisticsData {
    priceNative: string;
    priceUsd: string;
    liquidityUsd: string;
    fdv: string;
    symbol: string;
    dexscreen_url: string;
}




interface TokenDetails {
    id: string;
    token_symbol: string;
    dexscreen_url: string;
    token_name: string;
    image_url: string;
    token_description: string;
    token_sniffer_url: string;
    coingecko_url: string;
    price_usd: number;
    price_change_percentage_24h_in_currency: string;
    price_change_percentage_7d_in_currency: string;
    price_change_percentage_30d_in_currency: string;
    fdv: string;
    total_supply: string;
    max_supply: string;
    circulating_supply: string;
    market_cap: string;
    liquidity: string;
    h24v: string;
    token_address: string;
    links: {
        homepage: string[];
        twitter_screen_name: string,
        telegram_channel_identifier: string
    }
    categories: string[];
}


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

type Note = {
    id: string;
    notes: string;
    scope: string;
    created_at: string;
    wallet_id: string;
};

interface InfluencerProfile {
    description: string;
    username: string;
    name: string;
    public_metrics: {
        followers_count: number;
        following_count: number;
        tweet_count: number;
        listed_count: number;
        like_count: number;
    },
    entities: {
        url: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>
        },
        description: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>,
            mentions: Array<{
                start: number;
                end: number;
                username: string;
            }>
        }
    },
    url: string;
    location: string;
    created_at: string;
    verified: boolean;
    id: string;
    profile_image_url: string;
    pinned_tweet_id: string;
    protected: boolean;
    category: string;
}

export const TokenDetail = () => {
    const isMobile = useIsMobile();
    const { address, isConnected } = useAccount()
    const navigate = useNavigate();
    const { user, addToWatchlist, removeFromWatchlist, watchlistInfluencer, watchlistToken } = useUser();
    const { token } = useParams(); // Extract userId from the URL
    const [activeInfluencerTweetsTimeWindow, setActiveInfluencerTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [influencerTweets, setInfluencerTweets] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(null);
    const [pricesAtTime, setPricesAtTime] = useState();
    const [showTweetModal, setShowTweetModal] = useState(false);
    const [showSummaryTweetModal, setShowSummaryTweetModal] = useState(false);
    const [tweets, setTweets] = useState<Tweet[]>([]);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState<string | null>(null);
    const [userSelectedTweet, setUserSelectedTweet] = useState<any | null>(null);
    const [allTweetMention, setAllTweetMention] = useState<TweetMentionData[]>([]);
    const [tokenDetails, setTokenDetails] = useState<TokenDetails | null>(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const [dayWiseSummaryDate, setDayWiseSummaryDate] = useState('');
    const [dayWiseTweetSummary, setDayWiseTweetSummary] = useState<TweetSummaryData | null>(null);
    const [weeklyTweetSummary, setWeeklyTweetSummary] = useState<TweetSummaryData | null>(null);
    const [tokenData, setTokenData] = useState<TokenDetails>();
    const [influencerTweetloader, setInfluencerTweetloader] = useState(false);
    const [coingeckoStatistics, setCoingeckoStatistics] = useState<CoingeckoStatisticsData>({
        market_cap: '',
        high_24h: '',
        fdv: '',
        total_supply: '',
        max_supply: '',
        circulating_supply: '',
        price_usd: '',
        coingecko_url: '',
        token_description: '',
        price_change_percentage_24h_in_currency: '',
        price_change_percentage_7d_in_currency: '',
        price_change_percentage_30d_in_currency: '',
        links: {
            twitter_screen_name: '',
            telegram_channel_identifier: ''
        }
    });
    const [dexscreenerStatistics, setDexscreenerStatistics] = useState<DexscreenerStatisticsData>({
        priceNative: '',
        priceUsd: '',
        liquidityUsd: '',
        fdv: '',
        symbol: '',
        dexscreen_url: ''
    });


    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => setIsExpanded(!isExpanded);
    const MAX_LENGTH = 600;
    const renderDescription = (description: any) => {
        if (!description) {
            return ''; // Return an empty string or a default message if description is undefined or null
        }
        if (description.length <= MAX_LENGTH) {
            return description;
        }
        return isExpanded ? description : `${description.substring(0, MAX_LENGTH)}...`;
    };



    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Get Today's date
    const today = new Date();
    const [pastToDate, setPastToDate] = useState<Date | null>(today);


    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 7);
    const [pastDate, setPastDate] = useState<Date | null>(yesterday);

    const handlePastDateChange = async (date: Date | null) => {
        setPastDate(date);
        // const endDate = date ? new Date(date) : yesterday;
        // endDate.setDate(endDate.getDate() + 1);
        setStartDate(formatDateToUTC(date));

        // setEndDate(formatDate(endDate));
    };

    const handlePastToDateChange = async (date: Date | null) => {
        setPastToDate(date);
        // endDate.setDate(endDate.getDate() + 1);
        // setStartDate(formatDate(date || yesterday));
        setEndDate(formatDateToUTC(date));

    };


    const formatDateToUTC = (date: Date | null) => {
        if (!date) return '';

        const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        const year = utcDate.getUTCFullYear();
        const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = utcDate.getUTCDate().toString().padStart(2, '0');
        const hours = utcDate.getUTCHours().toString().padStart(2, '0');
        const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
        const seconds = utcDate.getUTCSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    };


    const formatDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };


    const [showFullDayWiseSummary, setShowFullDayWiseSummary] = useState(false);

    const toggleShowFullDayWiseSummary = () => {
        setShowFullDayWiseSummary(!showFullDayWiseSummary);
    };

    const [showFullWeeklySummary, setShowFullWeeklySummary] = useState(false);

    const toggleShowFullWeeklySummary = () => {
        setShowFullWeeklySummary(!showFullWeeklySummary);
    };


    const [currentBatch, setCurrentBatch] = useState(0);
    const [loadingTweets, setLoadingTweets] = useState(false);
    const [hasMoreTweets, setHasMoreTweets] = useState(true);
    const [currentType, setCurrentType] = useState('');
    const modalRef = useRef<HTMLDivElement>(null);

    const handleShowTweets = async (type: string) => {
        setCurrentBatch(0);
        setTweets([]);
        setHasMoreTweets(true);
        setShowSummaryTweetModal(true);
        setCurrentType(type);
        await loadMoreTweets(type, 0);
    };
    const fetchTweetDetails = async (tweetIds: string[]): Promise<{ [key: string]: Tweet }> => {
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: tweetIds,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                return data.tweets;
            } else {
                console.error('Failed to fetch tweet details', response.status);
                return {};
            }
        } catch (error) {
            console.error('Error fetching tweet details', error);
            return {};
        }
    };


    const loadMoreTweets = async (type: string, batch: number) => {
        if (loadingTweets || !hasMoreTweets) return;

        setLoadingTweets(true);

        try {
            const selectedTweets = weeklysentimentCounts[type].tweets;
            const startIndex = batch * 10;
            const endIndex = startIndex + 10;
            const batchTweets = selectedTweets.slice(startIndex, endIndex);
            console.log(batchTweets);

            if (batchTweets.length > 0) {
                const tweetIds = batchTweets.map((tweet) => tweet.id);
                const tweetDetails = await fetchTweetDetails(tweetIds);

                const updatedTweets = batchTweets.map((tweet) => ({
                    ...tweet,
                    publishable: tweetDetails[tweet.id]?.publishable,
                    complete_text: tweetDetails[tweet.id]?.tweet.complete_text,
                    user_profile_picture_url: tweetDetails[tweet.id]?.tweet.user_profile_picture_url,
                    username: tweetDetails[tweet.id]?.tweet?.username,
                    user_name: tweetDetails[tweet.id]?.tweet?.user_name,
                    created_at: tweetDetails[tweet.id]?.tweet?.created_at
                }));
                console.log(updatedTweets);

                setTweets((prevTweets) => {
                    // Filter out duplicates
                    const tweetIdsSet = new Set(prevTweets.map(tweet => tweet.id));
                    const newTweets = updatedTweets.filter(tweet => !tweetIdsSet.has(tweet.id));
                    return [...prevTweets, ...newTweets];
                });
                setCurrentBatch((prevBatch) => prevBatch + 1);

                if (endIndex >= selectedTweets.length) {
                    setHasMoreTweets(false);
                }
            } else {
                setHasMoreTweets(false);
            }
        } catch (error) {
            console.error("Error loading more tweets:", error);
        } finally {
            setLoadingTweets(false);
        }
    };

    const handleScroll = () => {
        if (modalRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = modalRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5 && hasMoreTweets && !loadingTweets) {
                loadMoreTweets(currentType, currentBatch);
            }
        }
    };

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMoreTweets, loadingTweets, currentBatch]);

    const get_tweet_statistics = async (token: any) => {
        const tokenParam = token ? `&token=${token}` : '';
        const url = `${process.env.REACT_APP_API_URL}detail/statistics?${tokenParam}`;
        // const url = `http://127.0.0.1:8000/detail/statistics?${tokenParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTokenData(data)
                // setCoingeckoStatistics(data['coingecko_statistics'])
                // setDexscreenerStatistics(data['dexscreener_statistics'])
                // if (data['coingecko_statistics']['price_usd']) {
                //     setPricesAtTime(data['coingecko_statistics']['price_usd'])
                // } else {
                //     setPricesAtTime(data['dexscreener_statistics']['priceUsd'])
                // }

            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    };




    const get_tweet_mentions = async (token: any) => {
        const tokenParam = token ? `&token=${token}` : '';
        const url = `${process.env.REACT_APP_API_URL}detail/mentions?${tokenParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setAllTweetMention(data['data']);
                setTokenDetails(data['token_details'])

            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const get_day_wise_tweet_summary = async (token: any, start_date: any, end_date: any, formatted_todate: any) => {
        // start_date = '17-12-2023'
        // end_date = '18-12-2023'
        setDayWiseSummaryDate(formatted_todate.replace(/-/g, '/'))
        let response = await get_tweet_summary(token, start_date, end_date)
        setDayWiseTweetSummary(response['data']);
    }


    const get_tweet_summary = async (token: any, start_date: any, end_date: any) => {

        const tokenParam = token ? `&token=${token}` : '';
        const startDateParam = start_date ? `&start_date=${start_date}` : '';
        const endDateParam = end_date ? `&end_date=${end_date}` : '';
        const url = `${process.env.REACT_APP_API_URL}detail/summary?${tokenParam}${startDateParam}${endDateParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                return data;

            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const get_influencer_tweet = async (token: any, page: any, timeWindow: any, sort?: string, reverse?: boolean) => {
        setInfluencerTweetloader(true)

        let url = `${process.env.REACT_APP_API_URL}detail/details?&token=${token}&time_window=${timeWindow}&page=${page}`;
        if (sort) {
            url += `&sort=${sort}&reverse=${reverse}`;
        }
        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setInfluencerTweets(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
                setInfluencerTweetloader(false)
            } else {
                console.log(response.status);
                setInfluencerTweetloader(false)
            }
        } catch (e) {
            console.log(e);
            setInfluencerTweetloader(false)
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };


    const fetchTokenPrice = async (tokenSymbol: any, tokenName: any) => {
        const url = `${process.env.REACT_APP_API_URL}home/token-price?token_symbol=${tokenSymbol}&token_name=${tokenName}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            setPricesAtTime(data.priceUsd)
        } catch (error) {
            console.error("Failed to fetch token price:", error);
            return null; // Handle error or return a default value
        }
    };


    const [publicNote, setPublicNote] = useState("");
    const [privateNote, setPrivateNote] = useState("");
    const [publicNotes, setPublicNotes] = useState<Note[]>([]);
    const [privateNotes, setPrivateNotes] = useState<Note[]>([]);
    const [submittedNote, setSubmittedNote] = useState("");
    const [activeTab, setActiveTab] = useState<'public' | 'private'>('private');
    const [editNoteId, setEditNoteId] = useState(null);
    const [editNoteContent, setEditNoteContent] = useState('');


    const handleEditNoteChange = (e: any) => {
        setEditNoteContent(e.target.value);
    };

    const handleEditClick = (note: any) => {
        setEditNoteId(note.id);
        setEditNoteContent(note.notes);
    };

    const handleSaveEdit = async (noteId: any) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}notes/${noteId}?wallet_id=${address}`;
            const payload = {
                notes: editNoteContent,
                scope: activeTab === 'public' ? 'Public' : 'Private',
                token: tokenData?.token_symbol
            };

            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                toast.error('Error updating note', {
                    style: {
                        background: 'rgba(255, 0, 0, 0.20)',
                        color: '#fff'
                    }
                });
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            toast.success('Note updated successfully', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            // Update the notes list with the edited content (This part depends on how you're managing the notes state)
            if (activeTab === 'public') {
                const updatedNotes = publicNotes.map(note => note.id === noteId ? { ...note, notes: editNoteContent } : note);
                setPublicNotes(updatedNotes);
            } else {
                const updatedNotes = privateNotes.map(note => note.id === noteId ? { ...note, notes: editNoteContent } : note);
                setPrivateNotes(updatedNotes);
            }

            setEditNoteId(null);
        } catch (error) {
            console.error('Error updating note:', error);
            toast.error('Error updating note', {
                style: {
                    background: 'rgba(255, 0, 0, 0.20)',
                    color: '#fff'
                }
            });
        }
    };

    const handleCancelEdit = () => {
        setEditNoteId(null);
        setEditNoteContent('');
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (activeTab === 'public') {
            setPublicNote(event.target.value);
        } else {
            setPrivateNote(event.target.value);
        }
    };

    const handleSubmit = async () => {
        const note = activeTab === 'public' ? publicNote : privateNote;
        const scope = activeTab === 'public' ? 'Public' : 'Private';

        if (note.trim()) {
            const url = `${process.env.REACT_APP_API_URL}notes/list?wallet_id=${address}`;
            const payload = JSON.stringify({
                "notes": note,
                "scope": scope,
                "token": tokenData?.token_symbol
            });
            const headers = {
                'Content-Type': 'application/json',
            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: payload
                });

                const result = await response.json();
                // Assuming the response contains the added note
                toast.success('Note added successfully', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });
                setPublicNote('')
                setPrivateNote('')
                fetchNotes()
            } catch (error) {
                console.error('Error adding note:', error);
                toast.error('Error adding note', {
                    style: {
                        background: 'rgba(255, 0, 0, 0.20)',
                        color: '#fff'
                    }
                });
            }
        }
    };


    const handleDeleteNote = async (noteId: string) => {
        const url = `${process.env.REACT_APP_API_URL}notes/${noteId}?wallet_id=${address}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: headers
            });

            if (response.ok) {
                if (activeTab === 'public') {
                    setPublicNotes(publicNotes.filter(note => note.id !== noteId));
                } else {
                    setPrivateNotes(privateNotes.filter(note => note.id !== noteId));
                }
                toast.success('Note deleted successfully', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });
            } else {
                console.error('Error deleting note:', response.statusText);
                toast.error('Error deleting note', {
                    style: {
                        background: 'rgba(255, 0, 0, 0.20)',
                        color: '#fff'
                    }
                });
            }
        } catch (error) {
            console.error('Error deleting note:', error);
            toast.error('Error deleting note', {
                style: {
                    background: 'rgba(255, 0, 0, 0.20)',
                    color: '#fff'
                }
            });
        }
    };

    const fetchNotes = async () => {
        const url = `${process.env.REACT_APP_API_URL}notes/list?wallet_id=${address}&token=${tokenData?.token_symbol}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: headers,
            });

            const result = await response.json();
            // Assuming the response structure
            setPublicNotes(result['data']['public'] || []);
            setPrivateNotes(result['data']['private'] || []);
        } catch (error) {
            console.error('Error fetching notes:', error);
        }
    };


    function formatAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }

    const handleSort = () => {
        const direction = sortDirection === 'asc' ? 'desc' : 'asc';
        const reverse = direction === 'desc';
        setSortDirection(direction);
        get_influencer_tweet(token, currentPage, activeInfluencerTweetsTimeWindow, 'price_since_tweet', reverse);
    };



    const [profileDetails, setProfileDetails] = useState<InfluencerProfile | null>(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [isPopoverHovered, setIsPopoverHovered] = useState(false);
    const ref = useRef(null);

    const fetchProfileDetails = async (username: any) => {
        setLoadingProfile(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}influencer/info?influencer=${username}`, {
                method: 'GET',
            });
            const data = await response.json();
            setProfileDetails(data['data']);
        } catch (error) {
            console.error("Error fetching profile details:", error);
        } finally {
            setLoadingProfile(false);
        }
    };

    const handleMouseEnter = (event: any, username: any) => {
        setTarget(event.target);
        setShow(true);
        fetchProfileDetails(username);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!isPopoverHovered) {
                setShow(false);
                setProfileDetails(null);
            }
        }, 100);
    };

    const handlePopoverMouseEnter = () => {
        setIsPopoverHovered(true);
    };

    const handlePopoverMouseLeave = () => {
        setIsPopoverHovered(false);
        setShow(false);
    };

    const handleLinkClick = (event: any) => {
        event.stopPropagation();
    };


    /* Show more/ less for token category */
    const [showAllCategory, setShowAllCategory] = useState(false);
    const displayCategories = showAllCategory ? tokenData?.categories : tokenData?.categories.slice(0, 3);


    useEffect(() => {
        fetchNotes();
    }, [tokenDetails]);

    useEffect(() => {
        let start = new Date();
        start.setDate(start.getDate() - 7); // Yesterday   
        let end = new Date(); // Today
        setStartDate(formatDateToUTC(start));
        setEndDate(formatDateToUTC(end));
    }, [token])

    useEffect(() => {
        get_tweet_mentions(token)
        get_tweet_statistics(token)

        //Get the yesterday summary
        const fromDate = new Date();
        const toDate = formatDate(new Date()) // Today's date
        // startDate.setDate(startDate.getDate() - 1); // Set to yesterday
        const formattedStartDate = fromDate.toISOString().split('T')[0].split('-').reverse().join('-');

        get_day_wise_tweet_summary(token, formatDate(fromDate), toDate, formattedStartDate)

    }, [token]); // Run this effect on component mount


    useEffect(() => {
        const fetchData = async () => {
            let response = await get_tweet_summary(token, startDate, endDate);
            setWeeklyTweetSummary(response['data']);
        };

        fetchData().catch(console.error); // Catch and log any errors in the async operation
    }, [startDate, endDate, token]); // Don't forget to include all external variables in the dependency array


    useEffect(() => {
        get_influencer_tweet(token, currentPage, activeInfluencerTweetsTimeWindow)
    }, [token, currentPage, activeInfluencerTweetsTimeWindow]);

    // useEffect(() => {
    //     fetchTokenPrice(tokenDetails?.token_symbol, token)
    // }, [influencerTweets]);



    if (!dayWiseTweetSummary) {
        return <div className='loader'>
            <Spinner animation="border" role="status" variant="light" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    const { summary: dayWiseSummary, tldr: dayWiseTldr } = dayWiseTweetSummary;
    const dayWisesentimentCounts = dayWiseSummary.reduce((acc: Record<string, number>, item) => {
        acc[item.category.toLowerCase()] = item.count;
        return acc;
    }, { bullish: 0, bearish: 0, neutral: 0 } as Record<string, number>);


    if (!weeklyTweetSummary) {
        return <div className='loader'>
            <Spinner animation="border" role="status" variant="light" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    const { summary: weeklySummary, tldr: weeklyTldr } = weeklyTweetSummary;
    const weeklysentimentCounts = weeklySummary.reduce<Record<string, SentimentCounts>>((acc, item) => {
        const category = item.category ? item.category.toLowerCase() : 'unknown';
        if (!acc[category]) {
            acc[category] = { count: 0, tweets: [] };
        }
        acc[category].count += item.count;
        acc[category].tweets = acc[category].tweets.concat(item.tweets || []);
        return acc;
    }, { bullish: { count: 0, tweets: [] }, bearish: { count: 0, tweets: [] }, neutral: { count: 0, tweets: [] }, unknown: { count: 0, tweets: [] } });

    const back_to_home = () => {
        // navigate(-1);
        navigate("/")
    }


    const generate_tweet = (token: any) => {
        navigate("/generate-tweet/" + token);
    }


    const checkIfTokenExistsOnWatchList = (tokenid: string): string | null => {
        if (watchlistToken?.length > 0) {
            const item = watchlistToken.find(item => item.data === tokenid);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleTokenWatchlistToggle = async (tokenid: any) => {
        console.log(tokenid)
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfTokenExistsOnWatchList(tokenid);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Token', isInWatchlist, user?.wallet_id)
            } else {
                console.log(user)
                addToWatchlist('Token', tokenid, user?.wallet_id)
            }
        }
        // Optionally, refresh the watchlist data to reflect the update
    };


    const checkIfExistsOnWatchList = (id: string): string | null => {
        if (watchlistInfluencer?.length > 0) {
            const item = watchlistInfluencer.find(item => item.data === id);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleWatchlistToggle = async (id: any) => {
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfExistsOnWatchList(id);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Influencer', isInWatchlist, user?.wallet_id)
            } else {
                console.log(user)
                addToWatchlist('Influencer', id, user?.wallet_id)
            }
        }
        // Optionally, refresh the watchlist data to reflect the update
    };


    const search_on_twitter = (query: string | undefined) => {
        if (query === undefined) {
            console.error("search_on_twitter was called with undefined query.");
            return; // Handle the undefined case or return early
        }
        const formattedQuery = query.startsWith('$') ? query : `$${query}`;
        window.open(`https://twitter.com/search?q=${encodeURIComponent(formattedQuery)}`, "_blank");
    }




    const view_tweet = async (tweet_id: any) => {


        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet_id];
                console.log(tweetData)
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_picture_url: tweetData.tweet.user_profile_picture_url,
                        user_name: tweetData.tweet.user_name,
                        username: tweetData.tweet.username,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_picture_url: tweetData.tweet.user_profile_picture_url,
                        user_name: tweetData.tweet.user_name,
                        username: tweetData.tweet.username,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(tweet_id)
                setShowTweetModal(true)
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    }

    const open_link = (url: string) => {
        window.open(url, "_blank")
    }

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const copyToClipboard = (text: any) => {

        navigator.clipboard.writeText(text)
            .then(() => {
                console.log(text)
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }


    const threshold = 1e-6;
    const formatNumber = (num: any) => {
        // Ensure the input is a number
        if (typeof num !== 'number' || isNaN(num)) {
            return `$ ${num}`;
        }

        // If the number is greater than or equal to the threshold, return it as a regular number
        if (num >= threshold) {
            return `$ ${num}`;
        }

        // Convert the number to fixed-point notation with a precision of 10 (6 zeros and 4 significant digits)
        let formattedNumber = num.toFixed(10);

        // Split the number to handle the fractional part separately
        let [integerPart, fractionalPart] = formattedNumber.split('.');

        // Format the fractional part to match the desired style
        const highlightedDigit = fractionalPart.slice(6, 7); // the first non-zero digit after six zeros
        const restOfDigits = fractionalPart.slice(7);

        return `<span class="chakra-text custom-0" title="$ ${formattedNumber}">$ ${integerPart}.0<span class="chakra-text custom-subscript">6</span>${highlightedDigit}${restOfDigits}</span>`;
    };


    function formatPrice(price: any) {
        const value = Number(price);

        if (isNaN(value)) {
            return `$0.00`; // Handle non-numeric input gracefully
        }

        if (value < 0.000000001) {
            return `$${value.toExponential(2)}`; // Use scientific notation for very small numbers
        } else {
            const formattedValue = value.toFixed(8); // Fixed to 8 decimal places for consistent formatting
            const parts = formattedValue.split('.');
            const integerPart = parts[0];
            const decimalPart = parts[1];

            if (parseInt(integerPart) > 10) {
                // For numbers greater than 10, return formatted value without HTML enhancements
                return `$${parseFloat(value.toFixed(8))}`;
            } else {
                let zeroCount = 0;
                let leadingZeros = '';
                let remainingDigits = '';

                // Count leading zeros and find the significant digits
                for (let i = 0; i < decimalPart.length; i++) {
                    if (decimalPart[i] === '0') {
                        zeroCount++;
                        leadingZeros += '0';
                    } else {
                        remainingDigits = decimalPart.slice(i);
                        break;
                    }
                }

                // Return formatted string with or without HTML embellishments based on zero count
                if (zeroCount > 4) {
                    return `
                        <div class="price">
                            <span class="chakra-text" title="$${formattedValue}">
                                $${integerPart}.0
                                <span class="chakra-text custom-subscript">${zeroCount}</span>${remainingDigits}
                            </span>
                        </div>
                    `;
                } else {
                    return `$${value}`;
                }
            }
        }
    }


    function formatTokenAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }


    function formatDatev1(dateInput: any) {
        // Check if dateInput is an object with a $date property
        const dateString = typeof dateInput === 'object' && dateInput !== null ? dateInput.$date : dateInput;

        if (!dateString) return '';

        // Ensure the dateString is treated as UTC if it doesn't include timezone information
        const date = new Date(dateString.includes('Z') ? dateString : `${dateString}Z`);

        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true, // Use 12-hour clock (AM/PM)
        }).format(date);
    }


    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };

    const open_influencer_detail = (id: any) => {
        window.open("/influencer-detail/" + id, "_blank")
    }





    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='back-btn' onClick={back_to_home}>
                                <Icon.ArrowLeft size={20}></Icon.ArrowLeft>  Back
                            </div>
                        </Col>

                    </Row>
                    <Row className='mt-3'>
                        <Col sm={5}>
                            <div className='d-flex align-items-center'>

                                <div>
                                    {tokenData &&
                                        <img alt='ticker-icon' src={(tokenData && tokenData.image_url != null) ? tokenData.image_url : no_img} className="t-d-ticker-img" />
                                    }
                                </div>

                                <div className="ms-1 t-d-ticker-name">{tokenData?.token_name}</div>
                                <div className="ms-1 mt-2 t-d-ticker-symbol">{tokenData?.token_symbol}</div>
                                {/* <div className="ms-1 ticker-symbol">BTC</div> */}
                                <div className="ms-2 t-d-ticker-price">
                                    {tokenData?.price_usd ? (
                                        <span dangerouslySetInnerHTML={{ __html: formatPrice(tokenData?.price_usd) }} />
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* <div className="ms-1 t-d-ticker-price">$37,378.71</div> */}
                            </div>
                            <div className="d-flex align-items-center token-address-container" onClick={() => copyToClipboard(tokenData?.token_address)}>
                                {tokenData && tokenData['links'] && Object.keys(tokenData.links).length > 0 && tokenData['links']['twitter_screen_name'] != '' &&
                                    <div className='me-2'><a href={'https://twitter.com/' + tokenData['links']['twitter_screen_name']} target='_blank'><img src={twitter_icon} /></a></div>
                                }
                                {tokenData && tokenData['links'] && Object.keys(tokenData.links).length > 0 && tokenData['links']['telegram_channel_identifier'] != '' &&
                                    <div className='me-2'><a href={'https://t.me/' + tokenData['links']['telegram_channel_identifier']} target='_blank'><img src={telegram_img} /></a></div>
                                }
                                {tokenData?.token_address &&
                                    <div className="token-address me-1">{formatTokenAddress(tokenData?.token_address)} {copySuccess ? <Icon.Check2 size={15} /> : <Icon.Copy className='cursor-pointer' size={15} />} </div>
                                }

                            </div>
                            {tokenData && tokenData['categories'] && tokenData['categories'].length > 0 &&
                                <>
                                    <div className='token-categories'>
                                        <span className='statistics-header'>Coingecko Categories:</span> {displayCategories?.join(', ')}
                                        {tokenData?.categories.length > 3 && (
                                            <span onClick={() => setShowAllCategory(!showAllCategory)} className='ms-2 read-more-btn'>
                                                {showAllCategory ? 'Show Less' : 'Show More'}
                                            </span>

                                        )}
                                    </div>

                                </>
                            }
                        </Col>
                        <Col sm={7}>
                            <div className='d-flex align-items-center justify-content-end' >
                                {tokenData && tokenData['links'] &&
                                    Object.keys(tokenData['links']).length > 0 &&
                                    Array.isArray(tokenData['links']['homepage']) &&
                                    tokenData['links']['homepage'].length > 0 &&
                                    tokenData['links']['homepage'][0] !== '' && (
                                        <div>
                                            <a href={tokenData['links']['homepage'][0]} target='_blank' rel="noreferrer"><Icon.Link45deg size={32} color='#fff' className="me-2"></Icon.Link45deg></a>
                                        </div>
                                    )}
                                {tokenData?.dexscreen_url != '' &&
                                    <div>
                                        <a href={tokenData?.dexscreen_url} target='_blank' rel="noreferrer"><img alt="dexscreen-icon" width={35} src={dexscreen_img} className="me-2 t-d-crypto-web-img" /></a>
                                    </div>
                                }
                                {tokenData?.coingecko_url != '' &&
                                    <div>
                                        <a href={tokenData?.coingecko_url} target='_blank' rel="noreferrer"><img alt="coingecko-icon" width={30} src={coingecko_img} className="t-d-crypto-web-img" /></a>
                                    </div>
                                }
                                <div className='ms-4 t-d-notes-btn cursor-pointer' onClick={() => setShowNotesModal(true)}>
                                    <Icon.CardText className='me-2'></Icon.CardText>
                                    Notes {(publicNotes.length + privateNotes.length) ? <span className='notes-count ms-1'>{(publicNotes.length + privateNotes.length)}</span> : ''}
                                </div>
                                {token && checkIfTokenExistsOnWatchList(token) ?
                                    (<div className='a-t-w ms-4 me-4 cursor-pointer' onClick={() => handleTokenWatchlistToggle(token)}>

                                        <img alt="watchlist-icon" className="me-2" src={star_fill_img}></img>
                                        Removed from Watchlist
                                    </div>) :
                                    (<div className='a-t-w ms-4 me-4 cursor-pointer' onClick={() => handleTokenWatchlistToggle(token)}>
                                        <img alt="remove-watchlist-icon" className="me-2" src={fav_img}></img>
                                        Add to Watchlist
                                    </div>)}
                                <div className='search-on-twitter me-4' onClick={() => search_on_twitter(tokenData?.token_symbol || '')}>
                                    <img alt="search-twitter" width={15} src={twitter_icon} /> Search On Twitter
                                </div>
                                <div className='t-d-ctw-btn' onClick={() => generate_tweet(token)}>
                                    Create Tweet
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={12}>
                            <Card className="t-w-card">
                                <Card.Body>
                                    <table className="token-table">
                                        <thead>
                                            <tr className="header-row">
                                                <th className="header-cell" rowSpan={2}>Price USD</th>
                                                <th className="header-cell" rowSpan={2}>Liquidity</th>
                                                <th className="header-cell" rowSpan={2}>Marketcap</th>
                                                <th className="header-cell" rowSpan={2}>FDV</th>
                                                <th className="header-cell" rowSpan={2}>Total Supply</th>
                                                <th className="header-cell" rowSpan={2}>Circulating Supply</th>
                                                <th className="header-cell" rowSpan={2}>24 hr Trading Volume</th>
                                                <th className="header-cell" colSpan={3}>Price Change Percentage</th>
                                            </tr>
                                            <tr className="header-row">
                                                <th className="header-cell">1D</th>
                                                <th className="header-cell">7D</th>
                                                <th className="header-cell">30D</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="data-cell">
                                                    {tokenData?.price_usd ? (
                                                        <span dangerouslySetInnerHTML={{ __html: formatPrice(tokenData?.price_usd) }} />
                                                    ) : (
                                                        'N/A'
                                                    )}


                                                </td>
                                                <td className="data-cell">{tokenData?.liquidity ? `$ ${tokenData.liquidity}` : 'N/A'}</td>
                                                <td className="data-cell">{tokenData?.market_cap ? `$ ${tokenData.market_cap}` : 'N/A'}</td>
                                                <td className="data-cell">{tokenData?.fdv ? `$ ${tokenData.fdv}` : 'N/A'}</td>
                                                <td className="data-cell">{tokenData?.total_supply ? `${tokenData.total_supply}` : 'N/A'}</td>
                                                <td className="data-cell">{tokenData?.circulating_supply ? `${tokenData.circulating_supply}` : 'N/A'}</td>
                                                <td className="data-cell">{tokenData?.h24v ? `$ ${tokenData.h24v}` : 'N/A'}</td>
                                                <td className="data-cell">
                                                    {tokenData?.price_change_percentage_24h_in_currency !== undefined
                                                        ? `${Number(tokenData.price_change_percentage_24h_in_currency).toFixed(2)} %`
                                                        : 'N/A'}
                                                </td>
                                                <td className="data-cell">
                                                    {tokenData?.price_change_percentage_7d_in_currency !== undefined
                                                        ? `${Number(tokenData.price_change_percentage_7d_in_currency).toFixed(2)} %`
                                                        : 'N/A'}
                                                </td>
                                                <td className="data-cell">
                                                    {tokenData?.price_change_percentage_30d_in_currency !== undefined
                                                        ? `${Number(tokenData.price_change_percentage_30d_in_currency).toFixed(2)} %`
                                                        : 'N/A'}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={6}>
                            <Card className="t-w-card t-t-m-card mt-5">
                                <Card.Body>
                                    <div className="tw-row-1 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className='statistics-header'>
                                                What is {tokenData?.token_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hr'></div>
                                    {tokenData &&
                                        <div className='statistics-token-description'>
                                            {renderDescription(tokenData['token_description'])}
                                            {tokenData['token_description'] && tokenData['token_description'].length > MAX_LENGTH && (
                                                <span onClick={toggleExpansion} className='read-more-btn'>
                                                    {isExpanded ? 'Show Less' : 'Show More'}
                                                </span>
                                            )}
                                        </div>
                                    }
                                    {/* <div className='statistics-token-description'>
                                        {coingeckoStatistics['token_description']}
                                    </div> */}

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className="t-w-card t-t-m-card mt-5">
                                <Card.Body>
                                    <div className="tw-row-1 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className='statistics-header'>
                                                Twitter Total Mentions
                                            </div>
                                        </div>

                                    </div>
                                    <div className='hr'></div>
                                    <div className="tw-row mt-4">
                                        <Row>
                                            <Col sm={12}>
                                                <div className='table-responsive'>

                                                    <table className='total-men-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>Time</th>
                                                                {allTweetMention.map(item => <th key={item.time_window}>{item.time_window}</th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>Tweets</th>
                                                                {allTweetMention.map(item => <td key={`tweet-${item.time_window}`}>{item.tweets}</td>)}
                                                            </tr>
                                                            <tr>
                                                                <th>Influ</th>
                                                                {allTweetMention.map(item => <td key={`influ-${item.time_window}`}>{item.influencers}</td>)}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row className='mt-5'>
                        <Col sm={12}>
                            <div className="s-heading">
                                Tweets Profile
                            </div>
                        </Col>
                    </Row> */}
                    <Row className='mt-4'>
                        <Col sm={6}>
                            <Card className="t-w-card t-t-m-card today-summary">
                                <Card.Body>
                                    <div>
                                        {tokenData && tokenData.links && tokenData.links.twitter_screen_name ? (
                                            <TwitterTimelineEmbed
                                                sourceType="profile"
                                                screenName={tokenData.links.twitter_screen_name}
                                                options={{ height: 600 }}
                                                theme="dark"
                                                placeholder={<Spinner animation="border" role="status" variant="light" >
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>}
                                            />
                                        ) : (
                                            <p className='text-center no-profile-data'>No Twitter feed available</p>
                                        )}
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card className={tokenData && tokenData.links && tokenData.links.twitter_screen_name ? "t-w-card t-t-m-card min-height-600" : "t-w-card t-t-m-card"}>
                                <Card.Body>
                                    <div className="tw-row-1 d-flex">
                                        <div className="">
                                            <div className='statistics-header'>
                                                Last Week’s Summary
                                            </div>
                                            <div className='mt-2'>
                                                <div className={isMobile ? 'd-flex mt-3' : 'mx-4 d-flex'}>
                                                    <div>
                                                        <label className='filter-label'>
                                                            From :
                                                        </label>
                                                        <DatePicker
                                                            selected={pastDate}
                                                            onChange={(date) => handlePastDateChange(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            maxDate={today}
                                                        />
                                                    </div>
                                                    <div className='mx-3'>
                                                        <label className='filter-label'>
                                                            To:
                                                        </label>
                                                        <DatePicker
                                                            selected={pastToDate}
                                                            onChange={(date) => handlePastToDateChange(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={pastDate}
                                                            maxDate={today}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='hr'></div>
                                    <div className="tw-row mt-4">
                                        <Row className=''>
                                            <Col sm={4} className='cursor-pointer' onClick={() => handleShowTweets('bullish')}>
                                                <div className='summary-num bullish'>{weeklysentimentCounts.bullish.count}</div>
                                                <div className='summary-label'>Bullish</div>
                                            </Col>
                                            <Col sm={4} className='cursor-pointer' onClick={() => handleShowTweets('bearish')}>
                                                <div className='summary-num bearish'>{weeklysentimentCounts.bearish.count}</div>
                                                <div className='summary-label'>Bearish</div>
                                            </Col>
                                            <Col sm={4} className='cursor-pointer' onClick={() => handleShowTweets('neutral')}>
                                                <div className='summary-num neutral'>{weeklysentimentCounts.neutral.count}</div>
                                                <div className='summary-label'>Neutral</div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="tw-row mt-4">
                                        <Row className=''>
                                            <Col sm={12}>
                                                <ol className='summary-text'>
                                                    {weeklyTldr.slice(0, showFullDayWiseSummary ? weeklyTldr.length : (tokenData && tokenData.links && tokenData.links.twitter_screen_name ? 11 : 3)).map((item, index) => (
                                                        <li key={index}>
                                                            <span className='summary-user'>{item.user_name}</span> - {item.tldr}
                                                            <span className='summary-tweet-link' onClick={() => view_tweet(item.tweet_id)}>View tweet</span>
                                                        </li>
                                                    ))}
                                                    {weeklyTldr.length > (tokenData && tokenData.links && tokenData.links.twitter_screen_name ? 11 : 3) &&
                                                        (<div onClick={toggleShowFullDayWiseSummary} className='read-more-btn'> {showFullDayWiseSummary ? 'Read Less' : 'Read More'}
                                                        </div>
                                                        )
                                                    }
                                                </ol>

                                            </Col>

                                        </Row>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </section >

            <section className="section-padding position-relative mt-5">
                {/* {influencerTweetloader && (
                    <div className="disabled-overlay">
                        Fetching Data...
                    </div>
                )} */}
                <div className="container-fluid">
                    <Row className='mt-5'>
                        <Col sm={12}>
                            <div className="s-heading">
                                Influencer’s Tweets
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className='d-flex'>
                            <div className="total-result  me-auto">
                                {/* 10 of 60 Results */}
                            </div>
                            <div className='time-window-container d-flex'>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('15min'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '15min' ? 'active' : ''}`}>
                                    15 min
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('1hr'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '1hr' ? 'active' : ''}`}>
                                    1h
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('4hr'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '4hr' ? 'active' : ''}`}>
                                    4h
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('12hr'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '12hr' ? 'active' : ''}`}>
                                    12h
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('1day'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '1day' ? 'active' : ''}`}>
                                    1d
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('1week'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '1week' ? 'active' : ''}`}>
                                    7d
                                </div>
                                <div onClick={() => { setActiveInfluencerTweetsTimeWindow('1month'); setSortDirection(null) }} className={`time-window ${activeInfluencerTweetsTimeWindow === '1month' ? 'active' : ''}`}>
                                    30d
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12} className='mt-3'>

                            {influencerTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!influencerTweetloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <colgroup>
                                                <col style={{ width: '2%' }} />
                                                <col style={{ width: '3%' }} />
                                                <col style={{ width: '20%' }} />
                                                <col style={{ width: '30%' }} />
                                                <col style={{ width: '15%' }} />
                                                <col style={{ width: '5%' }} />
                                                <col style={{ width: '13%' }} />
                                                <col style={{ width: '12%' }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Influencers Account</th>
                                                    <th>Tweet</th>
                                                    <th></th>
                                                    <th onClick={handleSort} style={{ cursor: 'pointer' }}>
                                                        Price Since Tweet
                                                        <div>
                                                            {sortDirection === 'asc' && <Icon.SortUp className='sort-icon' />}
                                                            {sortDirection === 'desc' && <Icon.SortDown className='sort-icon' />}
                                                            {sortDirection === null && <Icon.ArrowDownUp className='sort-icon' />}
                                                        </div>
                                                    </th>
                                                    <th>
                                                        Price at Time
                                                    </th>
                                                    <th>Current Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {influencerTweets.length > 0 ? (
                                                    influencerTweets.map((tweets, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(tweets['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(tweets['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td className='cursor-pointer'
                                                                onClick={() => open_influencer_detail(tweets['user_username'])}
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <div onMouseEnter={(e) => handleMouseEnter(e, tweets['user_username'])}
                                                                        onMouseLeave={handleMouseLeave}>
                                                                        <img alt="user-profile" src={tweets['user_profile_image_url']} className="t-d-user-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{tweets['user_name']}</div>
                                                                        <div className="ms-1 ticker-symbol">@{tweets['user_username']}</div>
                                                                        {tweets['user_tag'] &&
                                                                            <div className='influencer-tag'>{tweets['user_tag']}</div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <Overlay target={target} show={show} placement="top" container={ref.current}>
                                                                    <Popover id="popover-basic" className="popover-custom" onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave}>
                                                                        <Popover.Header as="h3">Profile Details</Popover.Header>
                                                                        <Popover.Body>
                                                                            {loadingProfile ? (
                                                                                <div>Loading...</div>
                                                                            ) : (
                                                                                <div>
                                                                                    <p><strong>Bio:</strong> {profileDetails?.description}</p>
                                                                                    {profileDetails?.url && (
                                                                                        <p>
                                                                                            <strong>URL:</strong> <a href={profileDetails.url} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">{profileDetails.url}</a>
                                                                                        </p>
                                                                                    )}
                                                                                    {profileDetails?.entities?.description?.mentions && profileDetails.entities.description.mentions.length > 0 && (
                                                                                        <p>
                                                                                            <strong>Mentions:</strong>{' '}
                                                                                            {profileDetails.entities.description.mentions.map((mention, index) => (
                                                                                                <span key={index}>
                                                                                                    <a href={`https://twitter.com/${mention.username}`} target="_blank" rel="noopener noreferrer">
                                                                                                        @{mention.username}
                                                                                                    </a>
                                                                                                    {index < profileDetails.entities.description.mentions.length - 1 && ', '}
                                                                                                </span>
                                                                                            ))}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                </Overlay>
                                                            </td>
                                                            <td>
                                                                <div className='token-detail-complete-text'>  {tweets['tweet_complete_text']}</div>
                                                                <div className='token-detail-timestamp'>
                                                                    {
                                                                        convertToLocalTime(tweets['tweet_created_at'])
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='view-tweet-btn' onClick={() => view_tweet(tweets['tweet_id'])}>
                                                                    View Tweet
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className={tweets['price_since_tweet'] >= 0 ? "percentage-increase" : "percentage-decrease"}>
                                                                    {tweets['price_since_tweet'] != '' ? ((tweets['price_since_tweet'] as number).toFixed(2) + '%') : tweets['price_since_tweet'] + '%'}
                                                                </span>
                                                            </td>
                                                            <td>

                                                                {tweets['price_usd'] ? (
                                                                    <span dangerouslySetInnerHTML={{ __html: formatPrice(tweets['price_usd']) }} />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td>
                                                                {tweets['current_price'] ? (
                                                                    <span dangerouslySetInnerHTML={{ __html: formatPrice(tweets['current_price']) }} />
                                                                ) : (
                                                                    ''
                                                                )}


                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={8} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                        <Modal className='tweet-modal modal-lg' show={showTweetModal} onHide={() => setShowTweetModal(false)} backdrop="static" keyboard={false} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div></div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {viewTweetId &&
                                                    <div data-theme="dark" className='modal-tweet-container'>
                                                        {userSelectedTweet && userSelectedTweet.publishable == true ? (

                                                            <Tweet id={userSelectedTweet.tweet_id} />
                                                        ) : (
                                                            <div className="not-found-tweet-container">
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <img src={userSelectedTweet.user_profile_picture_url} className="t-d-user-img" alt="User" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                                        <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                                                <div className='token-detail-timestamp'>
                                                                    {
                                                                        convertToLocalTime(userSelectedTweet.created_at)
                                                                    }
                                                                </div>
                                                            </div>

                                                            // <p className='tweet-complete-text'>{userSelectedTweet?.complete_text}</p>
                                                        )}
                                                    </div>
                                                }
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {viewTweetId &&
                                                    <Button className='go-to-tweet-btn' variant="secondary" onClick={() => open_tweet_link(viewTweetId)}>
                                                        Go To Tweet
                                                    </Button>
                                                }
                                            </Modal.Footer>
                                        </Modal>


                                        <Modal className='tweet-modal modal-lg' show={showSummaryTweetModal} onHide={() => setShowSummaryTweetModal(false)} backdrop="static" keyboard={false} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div></div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowSummaryTweetModal(false)}></Icon.XCircle>
                                            </Modal.Header>
                                            <Modal.Body >
                                                <div className="summary-tweet-modal" ref={modalRef}>
                                                    {tweets.length > 0 ? (
                                                        tweets.map((tweet, index) => (
                                                            <div key={index} className="tweet">
                                                                <div data-theme="dark">
                                                                    {tweet.publishable ? (
                                                                        <Tweet id={tweet.id} />
                                                                    ) : (
                                                                        <div className="not-found-tweet-container">
                                                                            <div className="d-flex align-items-center">
                                                                                <div>
                                                                                    <img src={tweet.user_profile_picture_url} className="t-d-user-img" alt="User" />
                                                                                </div>
                                                                                <div>
                                                                                    <div className="ms-1 ticker-name">{tweet.user_name}</div>
                                                                                    <div className="ms-1 ticker-symbol">@{tweet.username}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="tweet-complete-text">{tweet.complete_text}</div>
                                                                            <div className='token-detail-timestamp'>
                                                                                {
                                                                                    convertToLocalTime(tweet.created_at)
                                                                                }
                                                                            </div>
                                                                            <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(tweet.id)}>
                                                                                Go To Tweet
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (!loadingTweets ?
                                                        <p className='placeholder-text'>No tweets to display.</p> : ''
                                                    )}
                                                    {loadingTweets && <p className='placeholder-text'>Loading more tweets...</p>}
                                                </div>
                                            </Modal.Body>
                                            {/* <Modal.Footer>
                                                {viewTweetId &&
                                                    <Button className='go-to-tweet-btn' variant="secondary" onClick={() => open_tweet_link(viewTweetId)}>
                                                        Go To Tweet
                                                    </Button>
                                                }
                                            </Modal.Footer> */}
                                        </Modal>

                                        <Modal className='tweet-modal' show={showNotesModal} onHide={() => setShowNotesModal(false)} backdrop="static" keyboard={false} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div className='modal-heading'>Notes</div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowNotesModal(false)} />
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="notes-tabs">
                                                    <div
                                                        className={`notes-tab ${activeTab === 'public' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('public')}>
                                                        Public  {(publicNotes.length > 0) ? <span className='notes-count ms-1'>{(publicNotes.length)}</span> : ''}
                                                    </div>
                                                    <div
                                                        className={`notes-tab ${activeTab === 'private' ? 'active' : ''}`}
                                                        onClick={() => setActiveTab('private')}>
                                                        Private  {(privateNotes.length > 0) ? <span className='notes-count ms-1'>{(privateNotes.length)}</span> : ''}
                                                    </div>
                                                </div>
                                                {activeTab === 'private' ? (
                                                    <div className="note-content-section">
                                                        <div className="notes-container">
                                                            {privateNotes.map((note, index) => (
                                                                <div key={index} className="submitted-note-container">
                                                                    <div className="note-header">
                                                                        <span className="note-username">{formatAddress(note.wallet_id)}</span>
                                                                        {note.wallet_id == address && editNoteId !== note.id && (
                                                                            <div className="note-actions">
                                                                                <div className="note-edit-btn" onClick={() => handleEditClick(note)}>
                                                                                    <Icon.PenFill></Icon.PenFill>
                                                                                </div>
                                                                                <div className="note-delete-btn" onClick={() => handleDeleteNote(note.id)}>
                                                                                    <Icon.XCircle></Icon.XCircle>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/* <button className="note-delete-btn" onClick={() => handleDeleteNote(note.id)}>
                                                                            <Icon.XCircle></Icon.XCircle>
                                                            </button>*/}
                                                                    </div>
                                                                    <div className="note-content">
                                                                        {editNoteId === note.id ? (
                                                                            <div className=''>
                                                                                <div>
                                                                                    <textarea
                                                                                        className="note-edit-input"
                                                                                        value={editNoteContent}
                                                                                        onChange={handleEditNoteChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-actions">
                                                                                    <button className="note-save-btn" onClick={() => handleSaveEdit(note.id)}>
                                                                                        Save
                                                                                    </button>
                                                                                    <button className="note-cancel-btn" onClick={handleCancelEdit}>
                                                                                        Cancel
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {note.notes}
                                                                                <div className="note-time">
                                                                                    {convertToLocalTime(note.created_at)}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="note-input-container">
                                                            <textarea
                                                                className="note-input"
                                                                placeholder="Write a private note..."
                                                                value={privateNote}
                                                                onChange={handleNoteChange}
                                                            />
                                                            <button className="note-submit-btn" onClick={handleSubmit}>
                                                                <Icon.Send size={20} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="note-content-section">
                                                        <div className="notes-container">
                                                            {publicNotes.map((note, index) => (
                                                                <div key={index} className="submitted-note-container">
                                                                    <div className="note-header">
                                                                        <span className="note-username">{formatAddress(note.wallet_id)}</span>
                                                                        {note.wallet_id == address && editNoteId !== note.id && (
                                                                            <div className="note-actions">
                                                                                <div className="note-edit-btn" onClick={() => handleEditClick(note)}>
                                                                                    <Icon.PencilFill></Icon.PencilFill>
                                                                                </div>
                                                                                <div className="note-delete-btn" onClick={() => handleDeleteNote(note.id)}>
                                                                                    <Icon.XCircle></Icon.XCircle>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="note-content">
                                                                        {editNoteId === note.id ? (
                                                                            <div className=''>
                                                                                <div>
                                                                                    <textarea
                                                                                        className="note-edit-input"
                                                                                        value={editNoteContent}
                                                                                        onChange={handleEditNoteChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="edit-actions">
                                                                                    <button className="note-save-btn" onClick={() => handleSaveEdit(note.id)}>
                                                                                        Save
                                                                                    </button>
                                                                                    <button className="note-cancel-btn" onClick={handleCancelEdit}>
                                                                                        Cancel
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {note.notes}
                                                                                <div className="note-time">
                                                                                    {convertToLocalTime(note.created_at)}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="note-input-container">
                                                            <textarea
                                                                className="note-input"
                                                                placeholder="Write a public note..."
                                                                value={publicNote}
                                                                onChange={handleNoteChange}
                                                            />
                                                            <button className="note-submit-btn" onClick={handleSubmit}>
                                                                <Icon.Send size={20} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer></Modal.Footer>
                                        </Modal>
                                    </div>
                                    <div>
                                        {/* <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true"><Icon.ArrowLeft></Icon.ArrowLeft> Previous</span>
                                            </a>
                                        </li>
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item dots">...</li>
                                        <li className="page-item"><a className="page-link" href="#">6</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">Next <Icon.ArrowRight></Icon.ArrowRight> </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <div className="page-link" onClick={() => setCurrentPage(currentPage - 1)} aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </div>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <div className="page-link" onClick={() => setCurrentPage(currentPage + 1)} aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>

                                </>
                            }

                        </Col>
                    </Row>
                </div>
            </section>
        </div >

    )
}
export default TokenDetail