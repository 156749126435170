import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Table, Spinner, Button, Tooltip, Modal } from "react-bootstrap";
import Slider from "react-slick";
import { useUser } from '../components/UserContext';
import { parseISO, format } from 'date-fns';
import EcoSystemFilter from '../components/EcosystemFilter';
import TokenFilterForHome from '../components/TokenFilterForHome';
import InfluencerFilter from '../components/InfluencerFilter';
import KeywordFilter from '../components/KeywordFilter';
import CategoryFilter from '../components/CategoryFilter';
import { transformInfluencersToCategories, transformTokensToCategories, transformTokensToEcosystem } from '../components/utils';
import { Influencer, InfluencerCategory, Token, TokenCategory, MMTCategories } from '../components/filterTypes';
import { useAccount } from 'wagmi'
import { Tweet } from 'react-tweet';

import toast, { Toaster } from 'react-hot-toast';
import Select, { MultiValue, ActionMeta } from 'react-select';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import star_fill_img from "../assets/images/Star.png"
import star_table_img from "../assets/icons/Star 3.png"
import icon_1 from "../assets/icons/icon-1.png"
import icon_2 from "../assets/icons/icon-2.png"
import link_icon from "../assets/icons/link_icon.png"

import right_arrow_img from "../assets/icons/right-arrow.png"
import no_img from "../assets/images/no-image-icon.png"

import * as Icon from 'react-bootstrap-icons';
import "./home.css"
import { isNamedExports } from 'typescript';


interface Tweet {
    id: string;
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    user_name: string;
    user_username: string;
    dexscreen_url: string;
    token_sniffer_url: string;
    user_tag: string;
}

type TweetsByDate = {
    [key: string]: Tweet[];
};

interface CryptoTableRowProps {
    tweets: Tweet[];
}

interface ViewTweet {
    complete_text: string;
    tweet_id: string;
    publishable: boolean;
    user_profile_image_url: string;
    user_name: string;
    username: string;
    created_at: string;
}




const Avatars: React.FC<CryptoTableRowProps> = ({ tweets }) => {
    const { address, isConnected } = useAccount()
    const maxAvatars = 5;
    const remainingCount = tweets.length - maxAvatars;
    const [searchTerm, setSearchTerm] = useState("");
    const [isSortedByDate, setIsSortedByDate] = useState(true);
    const [showTweetModal, setShowTweetModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState<string | null>(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTweet, setSelectedTweet] = useState<ViewTweet | null>(null);
    const [userSelectedTweet, setUserSelectedTweet] = useState<ViewTweet | null>(null);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredTweets = tweets
        .filter(tweet =>
            tweet.user_username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tweet.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (isSortedByDate) {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const handleSortByDate = () => {
        setIsSortedByDate(!isSortedByDate);
    };


    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };


    const handleViewTweet = async (id: string) => {


        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(id);
                // setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }

    };

    const handleBack = () => {
        setViewTweetId(null);
    };


    const handleAvatarClick = async (tweet: Tweet) => {
        console.log(tweet)
        setViewTweetId(null);
        setUserSelectedTweet(null);
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet.tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet.tweet_id];
                console.log(tweetData)
                if (tweetData.publishable) {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    };

    return (
        <div>
            <div className="stacked-avatars">
                {tweets.slice(0, maxAvatars).map((tweet, index) => (
                    <div key={index} className="avatars__item" style={{ zIndex: tweets.length - index }} onClick={() => handleAvatarClick(tweet)}>
                        <img className="avatar-img" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div className="avatars__item avatars__more" style={{ zIndex: 0 }} onClick={() => setShowTweetModal(true)}>
                        +{remainingCount}
                    </div>
                )}
            </div>

            <Modal className='tweet-modal modal-lg' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered>
                <Modal.Header className="justify-content-between">
                    <div className='h-tweet-modal-h'>Tweets</div>
                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-container">
                        {viewTweetId && userSelectedTweet ? (
                            <div>
                                <button className="back-btn" onClick={handleBack}>Back To Tweets</button>
                                <div data-theme="dark" className='modal-tweet-container'>

                                    {userSelectedTweet && userSelectedTweet.publishable ? (
                                        <Tweet id={userSelectedTweet.tweet_id} />
                                    ) : (
                                        <div className="not-found-tweet-container">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={userSelectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                                </div>
                                                <div>
                                                    <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                    <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>

                                                </div>
                                            </div>
                                            <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                            <div className='token-detail-timestamp'>
                                                {
                                                    convertToLocalTime(userSelectedTweet.created_at)
                                                }
                                            </div>
                                            <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(userSelectedTweet.tweet_id)}>
                                                Go To Tweet
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="search-sort-container">
                                    <input
                                        type="text"
                                        placeholder="Search by Influencer..."
                                        className="search-bar"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="sort-btn" onClick={handleSortByDate}>
                                        Sort by Date {isSortedByDate ? '(Descending)' : '(Ascending)'}
                                    </button>
                                </div>
                                <div className="investor-list">
                                    {filteredTweets.map((tweet, index) => (
                                        <div key={index} className="investor-item">
                                            <img className="avatar-img-small" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />

                                            <div className="investor-info">
                                                <div className="investor-name">{tweet.user_name}</div>
                                                <div className="investor-type">{tweet.user_username}</div>
                                                {tweet?.user_tag &&
                                                    <div className='influencer-home-tag'>{tweet?.user_tag}</div>
                                                }
                                                <div className="tweet-date">
                                                    {new Date(tweet.created_at).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}{' '}
                                                    {new Date(tweet.created_at).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })}
                                                </div>
                                            </div>
                                            <button className="view-tweet-btn-modal" onClick={() => handleViewTweet(tweet.tweet_id)} >View Tweet</button>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            {selectedTweet && (
                <Modal className='tweet-modal' show={showDetailModal} onHide={() => setShowDetailModal(false)} centered>
                    <Modal.Header className="justify-content-between">
                        <div className='h-tweet-modal-h'>Tweet Details</div>
                        <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowDetailModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <div data-theme="dark" className='modal-tweet-container'>
                            {selectedTweet.publishable ? (
                                <Tweet id={selectedTweet.tweet_id} />
                            ) : (
                                <div className="not-found-tweet-container">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={selectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                        </div>
                                        <div>
                                            <div className="ms-1 ticker-name">{selectedTweet.user_name}</div>
                                            <div className="ms-1 ticker-symbol">@{selectedTweet.username}</div>
                                        </div>
                                    </div>
                                    <div className="tweet-complete-text">{selectedTweet.complete_text}</div>
                                    <div className='token-detail-timestamp'>
                                        {
                                            convertToLocalTime(selectedTweet.created_at)
                                        }
                                    </div>
                                    <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(selectedTweet.tweet_id)}>
                                        Go To Tweet
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}

        </div>
    );
};
const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isHovered ? '#a5dc86' : 'transparent',
        color: state.isHovered ? '#ffffff' : '#000000',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: '#a5dc86',
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: '#ffffff',
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: '#ffffff',
        ':hover': {
            backgroundColor: '#8cbf77',
            color: '#ffffff',
        },
    }),
};

interface TrendingTweet {
    id: string;
    price_usd: string;
    price_eth: string;
    h24: string;
    fdv: string;
    liquidity: string;
    token_name: string;
    token_address: string;
    token_symbol: string;
    dexscreen_url: string;
    chain_id: string;
    token_sniffer_url: string;
    total_tweets: number;
    isInWatchlist: boolean;
    image_url: string;
    market_cap: string;
}

interface TrendingTweets {
    [key: string]: TrendingTweet;
}

interface TokenOption {
    value: string;
    // ... other properties, if they exist
}



const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface Filters {
    tokens: Token[];
    keywords: string[];
    influencer: Influencer[];
}

export const Home = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const tokenFilterRef = useRef<{ resetFilter: () => void }>(null);
    const ecosystemFilterRef = useRef<{ resetFilter: () => void }>(null);
    const categoryFilterRef = useRef<{ resetFilter: () => void }>(null);
    const influencerFilterRef = useRef<{ resetFilter: () => void }>(null);
    const keywordFilterRef = useRef<{ resetFilters: () => void }>(null);
    const { user, addToWatchlist, removeFromWatchlist, watchlistToken } = useUser();
    const [isInfluencerWatchlistChecked, setIsInfluencerWatchlistChecked] = useState(false);
    const [isTokenWatchlistChecked, setIsTokenWatchlistChecked] = useState(false);
    const { address, isConnected } = useAccount()
    const dropdownRef = useRef<HTMLDivElement>(null);
    const TokenSlider = useRef<Slider | null>(null);
    const [activeTrendingTweetsTimeWindow, setActiveTrendingTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [activeAllTweetsTimeWindow, setActiveAllTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [trendingTweets, setTrendingTweets] = useState<TrendingTweets>({});
    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerCategory[]>([]);
    const [tokenFilter, setTokenFilter] = useState<string[]>([]);
    const [tokenFilterNew, setTokenFilterNew] = useState<Token[]>([]);
    const [tokenFilterWithoutCategory, setTokenFilterWithoutCategory] = useState<Token[]>([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [allTokenTweets, setAllTokenTweets] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [trendingTweetloader, setTrendingTweetloader] = useState(false);
    const [allTweetloader, setAllTweetloader] = useState(false);
    const [copiedAddress, setCopiedAddress] = useState(null);
    const [visibleLinkPopup, setVisibleLinkPopup] = useState(null);
    const [selectedFilterType, setSelectedFilterType] = useState('all');
    const [isEcosystemFilterApplied, setIsEcosystemFilterApplied] = useState(false);
    const [isCategoryFilterApplied, setIsCategoryFilterApplied] = useState(false);
    //Filter
    const [filters, setFilters] = useState<Filters>({ tokens: [], keywords: [], influencer: [] });
    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [filteredEcosystems, setFilteredEcosystems] = useState<string[]>([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);


    const handleResetClick = () => {
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
    };

    const handleResetEcosystemFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
    };

    const handleResetCategoryFilters = () => {
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
    };

    const handleResetInfluencerFilters = () => {
        if (influencerFilterRef.current) {
            influencerFilterRef.current.resetFilter();
        }
    };

    const handleResetKeywordFilters = () => {
        if (keywordFilterRef.current) {
            keywordFilterRef.current.resetFilters();
        }
    };

    const handleResetAllFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
        if (keywordFilterRef.current) {
            keywordFilterRef.current.resetFilters();
        }
        if (influencerFilterRef.current) {
            influencerFilterRef.current.resetFilter();
        }
        // Additional reset logic if required
    };

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        console.log(selectedValues)
        setFilteredTokens(selectedValues)
    };

    const handleSelectedItemsChange = (selectedItems: string[]) => {
        setSelectedInfluencers(selectedItems);

    };

    const handleEcosystemSelectionChange = (selectedValues: string[]) => {
        setFilteredEcosystems(selectedValues)
        setIsEcosystemFilterApplied(selectedValues.length > 0);
        handleResetClick();


    };

    const handleKeywordSelectionChange = (selectedValues: string[]) => {
        setFilteredKeywords(selectedValues)
    };

    const handleCategorySelectionChange = (selectedValues: string[]) => {
        setIsCategoryFilterApplied(selectedValues.length > 0);
        setFilteredCategories(selectedValues)
        handleResetClick();
        // const categories = Array.from(new Set(selectedValues.map(item => item.category)));
        // const tokens = Array.from(new Set(selectedValues.map(item => item.token)));
        // setFilteredCategories(categories);
        // setFilteredTokens(tokens);
    };




    useEffect(() => {
        get_trending_tweet(activeTrendingTweetsTimeWindow);
    }, [activeTrendingTweetsTimeWindow]);



    useEffect(() => {
        get_all_token_tweet(activeAllTweetsTimeWindow, currentPage, filteredTokens, filteredKeywords, filteredCategories, filteredEcosystems, selectedInfluencers, searchAddress);
    }, [activeAllTweetsTimeWindow, currentPage, filteredTokens, filteredKeywords, selectedInfluencers, isInfluencerWatchlistChecked, isTokenWatchlistChecked, searchAddress]);


    useEffect(() => {
        if (selectedFilterType == 'all') {
            get_all_token_tweet(activeAllTweetsTimeWindow, currentPage, filteredTokens, filteredKeywords, filteredCategories, filteredEcosystems, selectedInfluencers, searchAddress);
        }
        if (selectedFilterType == 'token') {
            fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
        }
    }, [filteredCategories, filteredEcosystems]);


    useEffect(() => {
        get_all_token_filter(activeAllTweetsTimeWindow);
    }, [activeAllTweetsTimeWindow]);

    useEffect(() => {
        fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
    }, []);

    const get_trending_tweet = async (timeWindow: any) => {
        setTrendingTweetloader(true)
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/trending-tweet?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTrendingTweets(data);
                setTrendingTweetloader(false)
            } else {
                setTrendingTweets({})
                setTrendingTweetloader(false)
            }
        } catch (e: any) {
            console.log(e);
            setTrendingTweets({})
            setTrendingTweetloader(false)
        }
    }

    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setFilters(data.filters);

                setKeywordFilter(data['filters']['keywords']);

                const categoriesWithInfluencers = transformInfluencersToCategories(data['filters']['influencer']);

                // Define the desired order
                const desiredOrder = ["Ethereum", "Solana", "Base", "Arbitrum One", "Optimism", "Polygon POS", "Polygon zkEVM", "TON", "Avalanche", "BNB Smart Chain", "zkSync", "Aptos", "Sui", "Fantom", "Cosmos", "Hyperliquid", "Linea", "Arbitrum Nova",  "BNB Beacon Chain"];

                // Sort the ecosystem array
                const sortedEcosystem = data['filters']['ecosystem'].sort((a: string, b: string) => {
                    const indexA = desiredOrder.indexOf(a);
                    const indexB = desiredOrder.indexOf(b);

                    // If either index is -1, move it to the end of the sorted array
                    if (indexA === -1) return 1;
                    if (indexB === -1) return -1;

                    return indexA - indexB;
                });

                // Set the state with the formatted data
                setTokenFilter(sortedEcosystem);

                setTokenFilterWithoutCategory(data['filters']['tokens']);
                setInfluencerFilter(categoriesWithInfluencers);
                setCategoryFilter(data['filters']['mmt_categories']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    };



    const get_all_token_tweet = async (timeWindow: any, page: any, token: any, keyword: any, category: any, ecosystem: any, influencer: any, search_address: any) => {
        setAllTweetloader(true);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const watchlistParam = isInfluencerWatchlistChecked ? '&influencer_watchlist=True' : '&influencer_watchlist=False';
        const tokenWatchlistParam = isTokenWatchlistChecked ? '&token_watchlist=True' : '&token_watchlist=False';

        const url = `${process.env.REACT_APP_API_URL}home/all-tweets?time_window=${timeWindow}&page=${page}${walletIdParam}${watchlistParam}${tokenWatchlistParam}`;
        console.log("Token" + token)
        const requestBody = {
            influencer: influencer ? influencer.join(',') : null,
            token: token ? token.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
            address: search_address ? search_address : null,
            mmt_category: (!token || token.length === 0) && category ? category.join(',') : null,
            ecosystem: (!token || token.length === 0) && ecosystem ? ecosystem.join(',') : null
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllTokenTweets(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
            setAllTweetloader(false);
        } finally {
            setAllTweetloader(false);
        }
    };

    const fetch_token_based_on_ecosystem_and_mmtcategory = async (category: any, ecosystem: any) => {
        const url = `${process.env.REACT_APP_API_URL}home/filters-pinpoint`;

        const payload = JSON.stringify({
            mmt_categories: category ? category.join(',') : null,
            ecosystem: ecosystem ? ecosystem.join(',') : null
        });

        const headers = {
            'Content-Type': 'application/json'
        };

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: payload
            });

            const data = await res.json();
            setTokenFilterNew(data['data']);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };


    const trending_tweet_slider_settings = {
        infinite: false,
        slidesToShow: 3,
        speed: 500,
        arrows: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    function formatTokenAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }

    const threshold = 1e-6;

    const formatNumber = (num: any) => {
        // Ensure the input is a number
        if (typeof num !== 'number' || isNaN(num)) {
            return `$ ${num}`;
        }

        // If the number is greater than or equal to the threshold, return it as a regular number
        if (num >= threshold) {
            return `$ ${num}`;
        }

        // Convert the number to fixed-point notation with a precision of 10 (6 zeros and 4 significant digits)
        let formattedNumber = num.toFixed(10);

        // Split the number to handle the fractional part separately
        let [integerPart, fractionalPart] = formattedNumber.split('.');

        // Format the fractional part to match the desired style
        const highlightedDigit = fractionalPart.slice(6, 7); // the first non-zero digit after six zeros
        const restOfDigits = fractionalPart.slice(7);

        return `<span class="chakra-text custom-0" title="$ ${formattedNumber}">$ ${integerPart}.0<span class="chakra-text custom-subscript">6</span>${highlightedDigit}${restOfDigits}</span>`;
    };



    function formatNumberForDisplay(value: any) {
        // First, ensure the value is a number
        const number = Number(value);

        // Check if the conversion failed which means value wasn't a number
        if (Number.isNaN(number)) {
            return 'Not a number'; // Or handle this case as appropriate for your app
        }

        // Define the threshold for what you consider a 'small' number
        const threshold = 1e-6;

        // Check if the number is 'small' based on the threshold
        if (Math.abs(number) < threshold) {
            // Format small number in scientific notation
            const [base, exponent] = number.toExponential(2).split('e');
            const exponentValue = parseInt(exponent, 10);
            return (
                <span>
                    {base} &times; 10<sup>{exponentValue}</sup>
                </span>
            );
        }

        // If the number is not 'small', return it in fixed-point notation with two decimal places
        // Adjust the '2' here if you want more or fewer decimal places for larger numbers
        return number.toFixed(2);
    }


    const toggleLinkPopup = (rowId: any) => {
        if (visibleLinkPopup === rowId) {
            setVisibleLinkPopup(null); // Close the popup if it's already open
        } else {
            setVisibleLinkPopup(rowId); // Open the new popup
        }
    };


    const checkIfExistsOnWatchList = (tokenId: string): string | null => {
        if (watchlistToken?.length > 0) {
            const item = watchlistToken.find(item => item.data === tokenId);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleWatchlistToggle = async (tokenId: any) => {
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfExistsOnWatchList(tokenId);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Token', isInWatchlist, user?.wallet_id)
            } else {
                addToWatchlist('Token', tokenId, user?.wallet_id)
            }
        }


        // Optionally, refresh the watchlist data to reflect the update
    };


    useEffect(() => {
        const slider = TokenSlider.current;
        // Ensure both slider and its innerSlider.list are defined
        if (!slider || !slider.innerSlider || !slider.innerSlider.list) return;

        const sliderElement = slider.innerSlider.list;
        const handleScroll = (event: any) => {
            const delta = Math.sign(event.deltaY);
            if (delta > 0) {
                slider.slickNext();
            } else {
                slider.slickPrev();
            }
        };

        sliderElement.addEventListener('wheel', handleScroll);

        return () => {
            sliderElement.removeEventListener('wheel', handleScroll);
        };
    }, []);


    useEffect(() => {
        const handleClickOutsidePopup = (event: any) => {
            if (visibleLinkPopup !== null && !event.target.closest('.popup')) {
                setVisibleLinkPopup(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutsidePopup);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePopup);
        };
    }, [visibleLinkPopup]);


    const open_token_detail = (token: any) => {
        if (user) {
            window.open("/token-detail/" + token, "_blank");
            // navigate("/token-detail/" + token);
        } else {
            toast.error('Please connect your wallat', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }

    }

    const back_generate_tweet = (token: any) => {
        if (user) {
            if (user?.credit > 0) {
                navigate("/generate-tweet/" + token);
            } else {
                toast.error('Your credit is low. please add balance to your account', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });
            }

        } else {
            toast.error('Please connect your wallat', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }

    }

    const copyToClipboard = async (address: any, key: any) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopiedAddress(key); // Use the unique key of the token address that was copied
            setTimeout(() => setCopiedAddress(null), 2000); // Optional: reset after a few seconds
        } catch (error) {
            console.error('Failed to copy to clipboard:', error);
        }
    };
    // Handle checkbox change
    const handleInfluencerCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsInfluencerWatchlistChecked(e.target.checked);
    };

    const handleTokenCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: '#470560',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsTokenWatchlistChecked(e.target.checked);
    };

    const handleAddressSearch = (query: any) => {
        setSearchAddress(query);
        // Implement your search logic here
    };


    const handleFilterTypeRadioChange = (event: any) => {
        setSelectedFilterType(event.target.value);
    };

    // Effect to reset checkbox if the user disconnects
    useEffect(() => {
        if (!isConnected) {
            setIsInfluencerWatchlistChecked(false);
            setIsTokenWatchlistChecked(false);
        }
    }, [isConnected]);



    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className={isMobile ? '' : 'd-flex'}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Trending Tweets
                                    </div>

                                </div>
                                <div className='time-window-container d-flex'>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('15min')} className={`time-window ${activeTrendingTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                                        15 min
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('1hr')} className={`time-window ${activeTrendingTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                                        1h
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('4hr')} className={`time-window ${activeTrendingTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                                        4h
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('12hr')} className={`time-window ${activeTrendingTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                                        12h
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('1day')} className={`time-window ${activeTrendingTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                                        1d
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('1week')} className={`time-window ${activeTrendingTweetsTimeWindow == '1week' ? 'active' : ''}`}>
                                        7d
                                    </div>
                                    <div onClick={() => setActiveTrendingTweetsTimeWindow('1month')} className={`time-window ${activeTrendingTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                                        30d
                                    </div>

                                </div>
                                {!trendingTweetloader &&
                                    <div className="slider-arrows">
                                        <button onClick={() => TokenSlider?.current?.slickPrev()} className="slider-arrow-left"><Icon.ArrowLeft size={30}></Icon.ArrowLeft> </button>
                                        <button onClick={() => TokenSlider?.current?.slickNext()} className="slider-arrow-right"><Icon.ArrowRight size={30}></Icon.ArrowRight></button>
                                    </div>
                                }
                            </div>
                            {!trendingTweetloader &&
                                <div className={isMobile ? 's-sub-heading mt-3' : 's-sub-heading'}>
                                    {Object.keys(trendingTweets).length} Results
                                </div>
                            }
                            <div className="mt-3">
                                <Row>
                                    {trendingTweetloader &&
                                        <Spinner animation="border" role="status" variant="light" >
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    }
                                    {!trendingTweetloader &&
                                        <Slider className="trending-slider-container" ref={TokenSlider}  {...trending_tweet_slider_settings}>
                                            {
                                                Object.keys(trendingTweets)
                                                    .filter(key => trendingTweets[key] && Object.keys(trendingTweets[key]).length > 0)
                                                    .map(key => {
                                                        // Assuming getWatchlistIdForToken returns the watchlist ID if the token is in the watchlist, otherwise null
                                                        const watchlistId = checkIfExistsOnWatchList(trendingTweets[key].id);
                                                        const isInWatchlist = watchlistId !== null;

                                                        const handleWatchlistClick = () => {
                                                            if (user?.wallet_id) {
                                                                if (isInWatchlist) {
                                                                    // Call function to remove from watchlist
                                                                    removeFromWatchlist('Token', watchlistId, user?.wallet_id); // Ensure this function exists and is implemented
                                                                } else {
                                                                    // Call function to add to watchlist
                                                                    addToWatchlist('Token', trendingTweets[key].id, user?.wallet_id); // Ensure this function exists and is implemented
                                                                }
                                                            }
                                                        };

                                                        return (
                                                            <div key={key}>
                                                                <Card className="t-w-card">
                                                                    <Card.Body>
                                                                        {/* Token details and other content */}
                                                                        <div className={isMobile ? 'tw-row-1' : 'tw-row-1 d-flex'}>
                                                                            <div className="d-flex align-items-center me-auto cursor-pointer" onClick={() => open_token_detail(trendingTweets[key].id)}>
                                                                                <div>
                                                                                    <img src={trendingTweets[key].image_url ? trendingTweets[key].image_url : no_img} className="ticker-img" />
                                                                                </div>
                                                                                <div className="ms-2">
                                                                                    <div className="ticker-name" data-fulltext={trendingTweets[key].token_name}>{trendingTweets[key].token_name}</div>
                                                                                    <div className="ticker-symbol">{trendingTweets[key].token_symbol}</div>
                                                                                </div>

                                                                            </div>
                                                                            <div className={isMobile ? 'mt-3 d-flex align-items-center' : 'd-flex align-items-center'}>
                                                                                <div className="t-tw d-flex align-items-center">
                                                                                    Total Tweets: {trendingTweets[key].total_tweets}
                                                                                    <img className="ms-2 right-arrow-img" src={right_arrow_img} />
                                                                                </div>
                                                                                <div className="ms-2 cursor-pointer" onClick={handleWatchlistClick}>
                                                                                    <img src={isInWatchlist ? star_fill_img : star_table_img} className="fav-fill" alt={isInWatchlist ? "Remove from Watchlist" : "Add to Watchlist"} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="tw-row-2 mt-3">
                                                                            {trendingTweets[key].token_address &&
                                                                                <div className="d-flex token-address-container" onClick={() => copyToClipboard(trendingTweets[key].token_address, key)}>
                                                                                    <div className="token-address me-1">
                                                                                        {formatTokenAddress(trendingTweets[key].token_address)}{" "}{copiedAddress === key ? <Icon.Check2 size={12} /> : <Icon.Copy className='cursor-pointer' size={12} />}
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="tw-row mt-4">
                                                                            <Row>
                                                                                <Col sm={6}>
                                                                                    <div className={isMobile ? 'tw-label mt-2' : 'tw-label'}>
                                                                                        Price
                                                                                    </div>
                                                                                    <div className="price">
                                                                                        {trendingTweets[key].price_usd ? (
                                                                                            <span dangerouslySetInnerHTML={{ __html: formatNumber(trendingTweets[key].price_usd) }} />
                                                                                        ) : (
                                                                                            'N/A'
                                                                                        )}
                                                                                    </div>

                                                                                </Col>
                                                                                <Col sm={6}>
                                                                                    <div className={isMobile ? 'tw-label mt-2' : 'tw-label'}>
                                                                                        24 Hrs High
                                                                                    </div>
                                                                                    <div className="price">
                                                                                        {trendingTweets[key].h24 ? (
                                                                                            <span dangerouslySetInnerHTML={{ __html: formatNumber(trendingTweets[key].h24) }} />
                                                                                        ) : (
                                                                                            'N/A'
                                                                                        )}
                                                                                    </div>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div className={isMobile ? 'tw-row' : 'tw-row mt-4'}>
                                                                            <Row>
                                                                                {/* <Col sm={6}>
                                                                        <div className="tw-label">
                                                                            Market Cap
                                                                        </div>
                                                                        <div className="tw-text">
                                                                            $ 727.60B
                                                                        </div>
                                                                    </Col> */}
                                                                                <Col sm={6}>
                                                                                    <div className={isMobile ? 'tw-label mt-2' : 'tw-label'}>
                                                                                        FDV
                                                                                    </div>
                                                                                    <div className="tw-text">
                                                                                        {trendingTweets[key].fdv ? '$ ' + trendingTweets[key].fdv : 'N/A'}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={6}>
                                                                                    <div className={isMobile ? 'tw-label mt-2' : 'tw-label'}>
                                                                                        Market Cap
                                                                                    </div>
                                                                                    <div className="tw-text">
                                                                                        {trendingTweets[key].market_cap ? '$ ' + trendingTweets[key].market_cap : 'N/A'}
                                                                                    </div>
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                        {/* <div className="tw-row mt-4">
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <div className="tw-label">
                                                                            Liquidity
                                                                        </div>
                                                                        <div className="tw-text">
                                                                            $ {trendingTweets[key].liquidity}
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </div> */}
                                                                        <div className="tw-row mt-4 d-flex align-items-center">
                                                                            <div className="me-auto d-flex">
                                                                                {/* <img className="me-2 tw-social-icons-1" src={twiiter_icon} />
                                                                    <img className="me-2 tw-social-icons-1" src={web_icon} />
                                                                    <img className="me-2 tw-social-icons-1" src={telegrm_icon} />
                                                                    <img className="me-2 tw-social-icons" src={discord_icon} /> */}
                                                                                {trendingTweets[key].dexscreen_url != '' &&
                                                                                    <a href={trendingTweets[key].dexscreen_url} target='_blank'><img className="me-2 tw-social-icons" src={icon_1} /></a>
                                                                                }
                                                                                {trendingTweets[key].token_sniffer_url != '' &&
                                                                                    <a href={trendingTweets[key].token_sniffer_url} target='_blank'><img className="me-2 tw-social-icons" src={icon_2} /></a>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <div className="ctw-btn" onClick={() => back_generate_tweet(trendingTweets[key].id)}>
                                                                                    Create Tweet
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                        );
                                                    })
                                            }

                                        </Slider>
                                    }

                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-5" style={{ position: 'relative' }}>
                {allTweetloader && (
                    <div className="disabled-overlay">
                        Fetching Data...
                    </div>
                )}
                <div className="container-fluid">
                    <Row>
                        <Col sm={12} className={isMobile ? '' : 'd-flex'}>
                            <div className="s-heading me-auto">
                                All Token Tweets
                            </div>
                            <div className='time-window-container d-flex'>
                                <div onClick={() => setActiveAllTweetsTimeWindow('15min')} className={`time-window ${activeAllTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                                    15 min
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('1hr')} className={`time-window ${activeAllTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                                    1h
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('4hr')} className={`time-window ${activeAllTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                                    4h
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('12hr')} className={`time-window ${activeAllTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                                    12h
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('1day')} className={`time-window ${activeAllTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                                    1d
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('1week')} className={`time-window ${activeAllTweetsTimeWindow == '1week' ? 'active' : ''}`}>
                                    7d
                                </div>
                                <div onClick={() => setActiveAllTweetsTimeWindow('1month')} className={`time-window ${activeAllTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                                    30d
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>

                                        <Col sm={12} md={12} lg={12}>
                                            <Card className="mb-3 filter-group-home">
                                                {/* <Card.Header>Filter Group</Card.Header> */}
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <Form>
                                                                <div className="d-inline-block me-5">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search by Ecosystem/Category"
                                                                        name="filterGroup"
                                                                        value="all"
                                                                        checked={selectedFilterType === 'all'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>
                                                                <div className="d-inline-block">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search Token"
                                                                        name="filterGroup"
                                                                        value="token"
                                                                        checked={selectedFilterType === 'token'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>

                                                            </Form>
                                                        </Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <div className="reset-all-filter-btn" onClick={handleResetAllFilters}>
                                                                Reset All Filters
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Ecosystem</div>
                                                            <EcoSystemFilter ref={ecosystemFilterRef} ecosystems={tokenFilter} onSelectionChange={handleEcosystemSelectionChange} />
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Category</div>
                                                            <CategoryFilter ref={categoryFilterRef} categories={categoryFilter} onSelectionChange={handleCategorySelectionChange} />
                                                        </Col>
                                                        {selectedFilterType === 'token' && (
                                                            <Col sm={12} md={4}>
                                                                <div className="filter-label">Filter by Token</div>

                                                                {(isEcosystemFilterApplied || isCategoryFilterApplied) && (
                                                                    <div className="filter-notes">
                                                                        {isEcosystemFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Ecosystem filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetEcosystemFilters}></Icon.X></div>}
                                                                        {isCategoryFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Category filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetCategoryFilters}></Icon.X></div>}
                                                                    </div>
                                                                )}
                                                                <TokenFilterForHome
                                                                    tokens={tokenFilterNew}
                                                                    onSelectedItemsChange={handleTokenSelectionChange}
                                                                    ref={tokenFilterRef}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        <Col sm={12} md={12} lg={3}>
                                            <div className="filter-label">
                                                Filter by Influencer
                                            </div>
                                            <InfluencerFilter ref={influencerFilterRef} influencers={influencerFilter} onSelectedItemsChange={handleSelectedItemsChange} />

                                        </Col>
                                        <Col sm={12} md={12} lg={3}>
                                            <div className="filter-label">
                                                Filter by Keywords
                                            </div>
                                            <KeywordFilter ref={keywordFilterRef} keywords={keywordFilter} onSelectionChange={handleKeywordSelectionChange} />

                                        </Col>
                                        <Col sm={12} md={12} lg={3}>
                                            <div className="filter-label mt-3">
                                                Filter by Contract Address
                                            </div>

                                            <input
                                                type="text"
                                                className="contract-address-search"
                                                placeholder="Enter contract address"
                                                value={searchAddress}
                                                onChange={(e) => handleAddressSearch(e.target.value)}
                                            />


                                        </Col>


                                        <Col sm={3}>
                                            <div className="mt-3 h-100">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Influencer Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isInfluencerWatchlistChecked}
                                                    onChange={handleInfluencerCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Token Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isTokenWatchlistChecked}
                                                    onChange={handleTokenCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12} className='mt-3'>

                            {allTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTweetloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Tokens</th>
                                                    <th> Total Tweets</th>
                                                    <th> Tweet Link</th>
                                                    <th>
                                                        Links
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTokenTweets.length > 0 ? (
                                                    allTokenTweets.map((tokenTweets, index) => (
                                                        <tr key={index} className={allTokenTweets.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'3%'}>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(tokenTweets['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(tokenTweets['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td width={'30%'}>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img src={tokenTweets['image_url'] ? tokenTweets['image_url'] : no_img} className="ticker-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className='d-flex align-items-center cursor-pointer' onClick={() => open_token_detail(tokenTweets['id'])}>
                                                                            <div className="ms-1 ticker-name">{tokenTweets['token_name'] ? tokenTweets['token_name'] : tokenTweets['cryptocurrency']} </div>
                                                                            <div className="ms-1 ticker-symbol">/ {tokenTweets['token_symbol']}</div>
                                                                        </div>
                                                                        {tokenTweets['token_address'] &&
                                                                            <div className="token-address ms-1" onClick={() => copyToClipboard(tokenTweets['token_address'], index)}>
                                                                                {formatTokenAddress(tokenTweets['token_address'])}{" "}{copiedAddress === index ? <Icon.Check2 size={12} /> : <Icon.Copy className='cursor-pointer' size={12} />}
                                                                            </div>
                                                                        }

                                                                    </div>

                                                                </div>

                                                            </td>

                                                            <td width={'5%'}>{tokenTweets['number_of_tweets']}</td>
                                                            <td width={'40%'}>
                                                                <Avatars tweets={tokenTweets['tweets']} />
                                                                {/* <CryptoTableRow tweetsByDate={tokenTweets['tweets']} /> */}

                                                            </td>
                                                            <td width={'5%'}>
                                                                <div className="position-relative"> {/* Add this wrapper */}
                                                                    <img className='table-link-icon' onClick={() => toggleLinkPopup(index)} src={link_icon} />
                                                                    {visibleLinkPopup === index &&
                                                                        <div className="popup">
                                                                            <a href={tokenTweets['dexscreen_url']} target='_blank'><img className="me-2 tw-social-icons cursor-pointer" src={icon_1} /></a>

                                                                            <a href={tokenTweets['token_sniffer_url']} target='_blank'><img className="me-2 tw-social-icons cursor-pointer" src={icon_2} /></a>
                                                                        </div>}
                                                                </div>
                                                            </td>
                                                            <td width={'15%'}>
                                                                <div className="ctw-btn" onClick={() => back_generate_tweet(tokenTweets['id'])}>
                                                                    Create Tweet
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div>
                                        {/* <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true"><Icon.ArrowLeft></Icon.ArrowLeft> Previous</span>
                                            </a>
                                        </li>
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item dots">...</li>
                                        <li className="page-item"><a className="page-link" href="#">6</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">Next <Icon.ArrowRight></Icon.ArrowRight> </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>

                </div>
            </section>
        </div>
    );
}

export default Home;